body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  background-color: #000000 !important;
  opacity: 1;
  background-image: url("./icons/backgroundAppDesk.jpg");
  background-size: cover;
  overflow: hidden;
  height: 100%;
}
@media only screen and (max-width: 600px) {
  body {
    background-image: url("./icons/backgroundAppMob.jpg");
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
