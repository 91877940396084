@import url("https://fonts.googleapis.com/css?family=Lato:300,900");
@import url("https://fonts.googleapis.com/css?family=Raleway");
@import url("https://fonts.googleapis.com/css?family=Lato:300,900");
:root {
  --glow-color: hsl(186, 100%, 69%);
}
.landerWrapper article,
.landerWrapper aside,
.landerWrapper footer,
.landerWrapper header,
.landerWrapper nav,
.landerWrapper section {
  display: block;
}
.landerWrapper h1 {
  font-size: 2em;
  margin: 0.67em 0;
}
.landerWrapper figcaption,
.landerWrapper figure,
.landerWrapper main {
  display: block;
}
.landerWrapper figure {
  margin: 1em 40px;
}
.landerWrapper hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}
.landerWrapper pre {
  font-family: monospace, monospace;
  font-size: 1em;
}

.landerWrapper abbr[title] {
  border-bottom: none;
  text-decoration: underline;
  -webkit-text-decoration: underline dotted;
  text-decoration: underline dotted;
}
.landerWrapper b,
.landerWrapper strong {
  font-weight: inherit;
}
.landerWrapper b,
.landerWrapper strong {
  font-weight: bolder;
}
.landerWrapper code,
.landerWrapper kbd,
.landerWrapper samp {
  font-family: monospace, monospace;
  font-size: 1em;
}
.landerWrapper dfn {
  font-style: italic;
}
.landerWrapper mark {
  background-color: #ff0;
  color: #000;
}
.landerWrapper small {
  font-size: 80%;
}
.landerWrapper sub,
.landerWrapper sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}
.landerWrapper sub {
  bottom: -0.25em;
}
.landerWrapper sup {
  top: -0.5em;
}
.landerWrapper audio,
.landerWrapper video {
  display: inline-block;
}
.landerWrapper audio:not([controls]) {
  display: none;
  height: 0;
}
.landerWrapper img {
  border-style: none;
}
.landerWrapper svg:not(:root) {
  overflow: hidden;
}
.landerWrapper button,
.landerWrapper input,
.landerWrapper optgroup,
.landerWrapper select,
.landerWrapper textarea {
  font-family: sans-serif;
  font-size: 100%;
  line-height: 1.15;
  margin: 0;
}
.landerWrapper button,
.landerWrapper input {
  overflow: visible;
}
.landerWrapper button,
.landerWrapper select {
  text-transform: none;
}
.landerWrapper button,
.landerWrapper button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  border-style: none;
  padding: 0;
}
.landerWrapper button:-moz-focusring,
[type="button"]:-moz-focusring,
[type="reset"]:-moz-focusring,
[type="submit"]:-moz-focusring {
  outline: 1px dotted ButtonText;
}
.landerWrapper fieldset {
  padding: 0.35em 0.75em 0.625em;
}
.landerWrapper legend {
  box-sizing: border-box;
  color: inherit;
  display: table;
  max-width: 100%;
  padding: 0;
  white-space: normal;
}
.landerWrapper progress {
  display: inline-block;
  vertical-align: baseline;
}
.landerWrapper textarea {
  overflow: auto;
}
.landerWrapper [type="checkbox"],
.landerWrapper [type="radio"] {
  box-sizing: border-box;
  padding: 0;
}
.landerWrapper [type="number"]::-webkit-inner-spin-button,
.landerWrapper [type="number"]::-webkit-outer-spin-button {
  height: auto;
}
.landerWrapper [type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}
.landerWrapper [type="search"]::-webkit-search-cancel-button,
.landerWrapper [type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}
.landerWrapper ::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}
.landerWrapper details,
.landerWrapper menu {
  display: block;
}
.landerWrapper summary {
  display: list-item;
}
.landerWrapper canvas {
  display: inline-block;
}
.landerWrapper template {
  display: none;
}
.landerWrapper [hidden] {
  display: none;
}
html {
  box-sizing: border-box;
}
*,
*:before,
*:after {
  box-sizing: inherit;
}
.landerWrapper {
  background: #1d2026;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
}
.landerWrapper hr {
  border: 0;
  display: block;
  height: 1px;
  background: #242830;
  margin-top: 24px;
  margin-bottom: 24px;
}
.landerWrapper ul,
.landerWrapper ol {
  margin-top: 0;
  margin-bottom: 24px;
  padding-left: 24px;
}
.landerWrapper ul {
  list-style: disc;
}
.landerWrapper ol {
  list-style: decimal;
}
.landerWrapper li > .landerWrapper ul,
.landerWrapper li > .landerWrapper ol {
  margin-bottom: 0;
}
.landerWrapper dl {
  margin-top: 0;
  margin-bottom: 24px;
}
.landerWrapper dt {
  font-weight: 600;
}
.landerWrapper dd {
  margin-left: 24px;
  margin-bottom: 24px;
}
.landerWrapper img {
  height: auto;
  max-width: 100%;
  vertical-align: middle;
}
.landerWrapper figure {
  margin: 24px 0;
}
.landerWrapper figcaption {
  font-size: 16px;
  line-height: 24px;
  padding: 8px 0;
}
.landerWrapper img,
.landerWrapper svg {
  display: block;
}
.landerWrapper table {
  border-collapse: collapse;
  margin-bottom: 24px;
  width: 100%;
}
.landerWrapper tr {
  border-bottom: 1px solid #242830;
}
.landerWrapper th {
  text-align: left;
}
.landerWrapper th,
.landerWrapper td {
  padding: 10px 16px;
}
.landerWrapper th:first-child,
.landerWrapper td:first-child {
  padding-left: 0;
}
.landerWrapper th:last-child,
.landerWrapper td:last-child {
  padding-right: 0;
}
.landerWrapper {
  font-size: 20px;
  line-height: 30px;
}
.landerWrapper {
  color: #8a94a7 !important;
}

.landerWrapper a {
  color: #8a94a7;
  text-decoration: underline;
}
.landerWrapper a:hover,
.landerWrapper a:active {
  outline: 0;
  text-decoration: none;
}
.landerWrapper h1,
.landerWrapper h2,
.landerWrapper h3,
.landerWrapper h4,
.landerWrapper h5,
.landerWrapper h6,
.landerWrapper .h1,
.landerWrapper .h2,
.landerWrapper .h3,
.landerWrapper .h4,
.landerWrapper .h5,
.landerWrapper .h6 {
  clear: both;
  color: #fff;
  font-weight: 600;
}
.landerWrapper h1,
.landerWrapper .h1 {
  font-size: 38px;
  line-height: 48px;
  letter-spacing: 0px;
}
@media (min-width: 641px) {
  .landerWrapper h1,
  .landerWrapper .h1 {
    font-size: 44px;
    line-height: 54px;
    letter-spacing: 0px;
  }
}
.landerWrapper h2,
.landerWrapper .h2 {
  font-size: 32px;
  line-height: 42px;
  letter-spacing: 0px;
}
@media (min-width: 641px) {
  .landerWrapper h2,
  .landerWrapper .h2 {
    font-size: 38px;
    line-height: 48px;
    letter-spacing: 0px;
  }
}
.landerWrapper h3,
.landerWrapper .h3,
.landerWrapper blockquote {
  font-size: 24px;
  line-height: 34px;
  letter-spacing: 0px;
}
@media (min-width: 641px) {
  .landerWrapper h3,
  .landerWrapper .h3,
  .landerWrapper blockquote {
    font-size: 32px;
    line-height: 42px;
    letter-spacing: 0px;
  }
}
.landerWrapper h4,
.landerWrapper h5,
.landerWrapper h6,
.landerWrapper .h4,
.landerWrapper .h5,
.landerWrapper .h6 {
  line-height: 30px;
  letter-spacing: -0.1px;
}
@media (min-width: 641px) {
  .landerWrapper h4,
  .landerWrapper h5,
  .landerWrapper h6,
  .landerWrapper .h4,
  .landerWrapper .h5,
  .landerWrapper .h6 {
    font-size: 24px;
    line-height: 34px;
    letter-spacing: 0px;
  }
}
@media (max-width: 640px) {
  .landerWrapper .h1-mobile {
    font-size: 38px;
    line-height: 48px;
    letter-spacing: 0px;
  }
  .section-inner .features-wrap .feature{
    margin: 1rem 0rem;
  }
  .pricing .section-paragraph {
    padding: 1rem;
  }
  .pricing-header{
    background: #15181d91;
    padding: 1rem;
  }
  .landerWrapper .feature {
    background-color: #15181dc2;
  }
  .body-wrap {
    background: url("../icons/bgfirst.jpg");
    background-size: cover;
  }
  .landerWrapper .h2-mobile {
    font-size: 32px;
    line-height: 42px;
    letter-spacing: 0px;
  }
  .landerWrapper .h3-mobile {
    font-size: 24px;
    line-height: 34px;
    letter-spacing: 0px;
  }
  .landerWrapper .h4-mobile,
  .landerWrapper .h5-mobile,
  .landerWrapper .h6-mobile {
    font-size: 20px;
    line-height: 30px;
    letter-spacing: -0.1px;
  }
}
.landerWrapper .text-light h1,
.landerWrapper .text-light h2,
.landerWrapper .text-light h3,
.landerWrapper .text-light h4,
.landerWrapper .text-light h5,
.landerWrapper .text-light h6,
.landerWrapper .text-light .h1,
.landerWrapper .text-light .h2,
.landerWrapper .text-light .h3,
.landerWrapper .text-light .h4,
.landerWrapper .text-light .h5,
.landerWrapper .text-light .h6 {
  color: white !important;
}
.landerWrapper .text-sm {
  font-size: 18px;
  line-height: 28px;
  letter-spacing: -0.1px;
}
.landerWrapper .text-xs {
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.1px;
}
.landerWrapper h1,
.landerWrapper h2,
.landerWrapper .h1,
.landerWrapper .h2 {
  margin-top: 48px;
  margin-bottom: 16px;
}
.landerWrapper h3,
.landerWrapper .h3 {
  margin-top: 36px;
  margin-bottom: 12px;
}
.landerWrapper h4,
.landerWrapper h5,
.landerWrapper h6,
.landerWrapper .h4,
.landerWrapper .h5,
.landerWrapper .h6 {
  margin-top: 24px;
  margin-bottom: 4px;
}
.landerWrapper p {
  margin-top: 0;
  margin-bottom: 24px;
  color: white;
}
.landerWrapper dfn,
.landerWrapper cite,
.landerWrapper em,
.landerWrapper i {
  font-style: italic;
}
.landerWrapper blockquote {
  color: #3b404c;
  font-style: italic;
  margin-top: 24px;
  margin-bottom: 24px;
  margin-left: 24px;
}
.landerWrapper blockquote::before {
  content: "\201C";
}
.landerWrapper blockquote::after {
  content: "\201D";
}
.landerWrapper blockquote p {
  display: inline;
}
.landerWrapper address {
  color: #8a94a7;
  border-width: 1px 0;
  border-style: solid;
  border-color: #242830;
  padding: 24px 0;
  margin: 0 0 24px;
}
.landerWrapper pre,
.landerWrapper pre h1,
.landerWrapper pre h2,
.landerWrapper pre h3,
.landerWrapper pre h4,
.landerWrapper pre h5,
.landerWrapper pre h6,
.landerWrapper pre .h1,
.landerWrapper pre .h2,
.landerWrapper pre .h3,
.landerWrapper pre .h4,
.landerWrapper pre .h5,
.landerWrapper pre .h6 {
  font-family: "Courier 10 Pitch", Courier, monospace;
}
.landerWrapper .landerWrapper pre,
.landerWrapper .landerWrapper code,
.landerWrapper .landerWrapper kbd,
.landerWrapper .landerWrapper tt,
.landerWrapper .landerWrapper var {
  background: #1d2026;
}
.landerWrapper pre {
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 1.6em;
  max-width: 100%;
  overflow: auto;
  padding: 24px;
  margin-top: 24px;
  margin-bottom: 24px;
}
.landerWrapper code,
.landerWrapper kbd,
.landerWrapper tt,
.landerWrapper var {
  font-family: Monaco, Consolas, "Andale Mono", "DejaVu Sans Mono", monospace;
  font-size: 16px;
  padding: 2px 4px;
}
.landerWrapper abbr,
.landerWrapper acronym {
  cursor: help;
}
.landerWrapper mark,
.landerWrapper ins {
  text-decoration: none;
}
.landerWrapper small {
  font-size: 18px;
  line-height: 28px;
  letter-spacing: -0.1px;
}
.landerWrapper b,
.landerWrapper strong {
  font-weight: 600;
}
.landerWrapper button,
.landerWrapper input,
.landerWrapper select,
.landerWrapper textarea,
.landerWrapper label {
  font-size: 20px;
  line-height: 30px;
}
.landerWrapper .container,
.landerWrapper .container-sm {
  width: 100%;
  margin: 0 auto;
  padding-left: 16px;
  padding-right: 16px;
}
@media (min-width: 481px) {
  .landerWrapper .container,
  .landerWrapper .container-sm {
    padding-left: 24px;
    padding-right: 24px;
  }
}
.landerWrapper .container-sm {
  max-width: 848px;
}
.landerWrapper .container .landerWrapper .container-sm {
  max-width: 800px;
  padding-left: 0;
  padding-right: 0;
}
.landerWrapper .screen-reader-text {
  clip: rect(1px, 1px, 1px, 1px);
  position: absolute !important;
  height: 1px;
  width: 1px;
  overflow: hidden;
  word-wrap: normal !important;
}
.landerWrapper .screen-reader-text:focus {
  border-radius: 2px;
  box-shadow: 0 0 2px 2px rgba(0, 0, 0, 0.6);
  clip: auto !important;
  display: block;
  font-size: 14px;
  letter-spacing: 0px;
  font-weight: 600;
  line-height: 16px;
  text-decoration: none;
  text-transform: uppercase;
  background-color: #1d2026;
  color: #0270d7 !important;
  border: none;
  height: auto;
  left: 8px;
  padding: 16px 32px;
  top: 8px;
  width: auto;
  z-index: 100000;
}
.landerWrapper .list-reset {
  list-style: none;
  padding: 0;
}
.landerWrapper .text-left {
  text-align: left;
}
.landerWrapper .text-center {
  text-align: center;
}
.landerWrapper .text-right {
  text-align: right;
}
.landerWrapper .text-primary {
  color: #0270d7;
}
.landerWrapper .has-top-divider {
  position: relative;
}
.landerWrapper .has-top-divider::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  display: block;
  height: 1px;
  background: #242830;
}
.landerWrapper .has-bottom-divider {
  position: relative;
}
.landerWrapper .has-bottom-divider::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  display: block;
  height: 1px;
  background: #242830;
}
.landerWrapper .m-0 {
  margin: 0;
}
.landerWrapper .mt-0 {
  margin-top: 0;
}
.landerWrapper .mr-0 {
  margin-right: 0;
}
.landerWrapper .mb-0 {
  margin-bottom: 0;
}
.landerWrapper .ml-0 {
  margin-left: 0;
}
.landerWrapper .m-8 {
  margin: 8px;
}
.landerWrapper .mt-8 {
  margin-top: 8px;
}
.landerWrapper .mr-8 {
  margin-right: 8px;
}
.landerWrapper .mb-8 {
  margin-bottom: 8px;
}
.landerWrapper .ml-8 {
  margin-left: 8px;
}
.landerWrapper .m-16 {
  margin: 16px;
}
.landerWrapper .mt-16 {
  margin-top: 16px;
}
.landerWrapper .mr-16 {
  margin-right: 16px;
}
.landerWrapper .mb-16 {
  margin-bottom: 16px;
}
.landerWrapper .ml-16 {
  margin-left: 16px;
}
.landerWrapper .m-24 {
  margin: 24px;
}
.landerWrapper .mt-24 {
  margin-top: 24px;
}
.landerWrapper .mr-24 {
  margin-right: 24px;
}
.landerWrapper .mb-24 {
  margin-bottom: 24px;
}
.landerWrapper .ml-24 {
  margin-left: 24px;
}
.landerWrapper .m-32 {
  margin: 32px;
}
.landerWrapper .mt-32 {
  margin-top: 32px;
}
.landerWrapper .mr-32 {
  margin-right: 32px;
}
.landerWrapper .mb-32 {
  margin-bottom: 32px;
}
.landerWrapper .ml-32 {
  margin-left: 32px;
}
.landerWrapper .m-40 {
  margin: 40px;
}
.landerWrapper .mt-40 {
  margin-top: 40px;
}
.landerWrapper .mr-40 {
  margin-right: 40px;
}
.landerWrapper .mb-40 {
  margin-bottom: 40px;
}
.landerWrapper .ml-40 {
  margin-left: 40px;
}
.landerWrapper .m-48 {
  margin: 48px;
}
.landerWrapper .mt-48 {
  margin-top: 48px;
}
.landerWrapper .mr-48 {
  margin-right: 48px;
}
.landerWrapper .mb-48 {
  margin-bottom: 48px;
}
.landerWrapper .ml-48 {
  margin-left: 48px;
}
.landerWrapper .m-56 {
  margin: 56px;
}
.landerWrapper .mt-56 {
  margin-top: 56px;
}
.landerWrapper .mr-56 {
  margin-right: 56px;
}
.landerWrapper .mb-56 {
  margin-bottom: 56px;
}
.landerWrapper .ml-56 {
  margin-left: 56px;
}
.landerWrapper .m-64 {
  margin: 64px;
}
.landerWrapper .mt-64 {
  margin-top: 64px;
}
.landerWrapper .mr-64 {
  margin-right: 64px;
}
.landerWrapper .mb-64 {
  margin-bottom: 64px;
}
.landerWrapper .ml-64 {
  margin-left: 64px;
}
.landerWrapper .p-0 {
  padding: 0;
}
.landerWrapper .pt-0 {
  padding-top: 0;
}
.landerWrapper .pr-0 {
  padding-right: 0;
}
.landerWrapper .pb-0 {
  padding-bottom: 0;
}
.landerWrapper .pl-0 {
  padding-left: 0;
}
.landerWrapper .p-8 {
  padding: 8px;
}
.landerWrapper .pt-8 {
  padding-top: 8px;
}
.landerWrapper .pr-8 {
  padding-right: 8px;
}
.landerWrapper .pb-8 {
  padding-bottom: 8px;
}
.landerWrapper .pl-8 {
  padding-left: 8px;
}
.landerWrapper .p-16 {
  padding: 16px;
}
.landerWrapper .pt-16 {
  padding-top: 16px;
}
.landerWrapper .pr-16 {
  padding-right: 16px;
}
.landerWrapper .pb-16 {
  padding-bottom: 16px;
}
.landerWrapper .pl-16 {
  padding-left: 16px;
}
.landerWrapper .p-24 {
  padding: 24px;
}
.landerWrapper .pt-24 {
  padding-top: 24px;
}
.landerWrapper .pr-24 {
  padding-right: 24px;
}
.landerWrapper .pb-24 {
  padding-bottom: 24px;
}
.landerWrapper .pl-24 {
  padding-left: 24px;
}
.landerWrapper .p-32 {
  padding: 32px;
}
.landerWrapper .landerWrapper .pt-32 {
  padding-top: 32px;
}
.landerWrapper .landerWrapper .pr-32 {
  padding-right: 32px;
}
.landerWrapper .landerWrapper .pb-32 {
  padding-bottom: 32px;
}
.landerWrapper .landerWrapper .pl-32 {
  padding-left: 32px;
}
.landerWrapper .landerWrapper .p-40 {
  padding: 40px;
}
.landerWrapper .landerWrapper .pt-40 {
  padding-top: 40px;
}
.landerWrapper .landerWrapper .pr-40 {
  padding-right: 40px;
}
.landerWrapper .landerWrapper .pb-40 {
  padding-bottom: 40px;
}
.landerWrapper .landerWrapper .pl-40 {
  padding-left: 40px;
}
.landerWrapper .landerWrapper .p-48 {
  padding: 48px;
}
.landerWrapper .landerWrapper .pt-48 {
  padding-top: 48px;
}
.landerWrapper .landerWrapper .pr-48 {
  padding-right: 48px;
}
.landerWrapper .landerWrapper .pb-48 {
  padding-bottom: 48px;
}
.landerWrapper .landerWrapper .pl-48 {
  padding-left: 48px;
}
.landerWrapper .landerWrapper .p-56 {
  padding: 56px;
}
.landerWrapper .landerWrapper .pt-56 {
  padding-top: 56px;
}
.landerWrapper .landerWrapper .pr-56 {
  padding-right: 56px;
}
.landerWrapper .landerWrapper .pb-56 {
  padding-bottom: 56px;
}
.landerWrapper .pl-56 {
  padding-left: 56px;
}
.landerWrapper .p-64 {
  padding: 64px;
}
.landerWrapper .pt-64 {
  padding-top: 64px;
}
.landerWrapper .pr-64 {
  padding-right: 64px;
}
.landerWrapper .pb-64 {
  padding-bottom: 64px;
}
.landerWrapper .pl-64 {
  padding-left: 64px;
}
.sr .has-animations .is-revealing {
  visibility: hidden;
}
.has-animations .anime-element {
  visibility: hidden;
}
.anime-ready .has-animations .anime-element {
  visibility: visible;
}
.anime-ready .has-animations .anime-element .placeholder {
  visibility: hidden;
}
.landerWrapper .input,
.landerWrapper .textarea {
  background-color: #fff;
  border-width: 1px;
  border-style: solid;
  border-color: #242830;
  border-radius: 2px;
  color: #8a94a7;
  max-width: 100%;
  width: 100%;
}
.landerWrapper .input::-moz-placeholder,
.landerWrapper .textarea::-moz-placeholder {
  color: #3b404c;
}
.landerWrapper .input::placeholder,
.landerWrapper .textarea::placeholder {
  color: #3b404c;
}
.landerWrapper .input::-ms-input-placeholder,
.landerWrapper .textarea::-ms-input-placeholder {
  color: #3b404c;
}
.landerWrapper .input:-ms-input-placeholder,
.landerWrapper .textarea:-ms-input-placeholder {
  color: #3b404c;
}
.landerWrapper .input:hover,
.landerWrapper .textarea:hover {
  border-color: #191c21;
}
.landerWrapper .input:active,
.landerWrapper .input:focus,
.landerWrapper .textarea:active,
.landerWrapper .textarea:focus {
  outline: none;
  border-color: #242830;
}
.landerWrapper .input[disabled],
.landerWrapper .textarea[disabled] {
  cursor: not-allowed;
  background-color: #1d2026;
  border-color: #1d2026;
}
.landerWrapper .input {
  -moz-appearance: none;
  -webkit-appearance: none;
  font-size: 16px;
  letter-spacing: -0.1px;
  line-height: 20px;
  padding: 13px 16px;
  height: 48px;
  box-shadow: none;
}
.landerWrapper .input .inline-input {
  display: inline;
  width: auto;
}
.landerWrapper .textarea {
  display: block;
  min-width: 100%;
  resize: vertical;
}
.landerWrapper .textarea .inline-textarea {
  display: inline;
  width: auto;
}
.landerWrapper .field-grouped > .control:not(:last-child) {
  margin-bottom: 8px;
}
@media (min-width: 641px) {
  .landerWrapper .field-grouped {
    display: flex;
  }
  .landerWrapper .field-grouped > .landerWrapper .control {
    flex-shrink: 0;
  }
  .landerWrapper .field-grouped > .landerWrapper .control.control-expanded {
    flex-grow: 1;
    flex-shrink: 1;
  }
  .landerWrapper .field-grouped > .landerWrapper .control:not(:last-child) {
    margin-bottom: 0;
    margin-right: 8px;
  }
}
.landerWrapper .button {
  display: inline-flex;
  font-size: 14px;
  letter-spacing: 0px;
  font-weight: 600;
  line-height: 16px;
  text-decoration: none !important;
  text-transform: uppercase;
  background-color: #242830;
  color: #fff !important;
  border: none;
  border-radius: 2px;
  cursor: pointer;
  justify-content: center;
  padding: 16px 32px;
  height: 48px;
  text-align: center;
  white-space: nowrap;
}
.landerWrapper .button:hover {
  background: #262a33;
}
.landerWrapper .button:active {
  outline: 0;
}
.landerWrapper .button::before {
  border-radius: 2px;
}
.landerWrapper .button-sm {
  padding: 8px 24px;
  height: 32px;
}
.landerWrapper .button-primary {
  background: #097dea;
  background: linear-gradient(65deg, #0270d7 0, #0f8afd 100%);
}
.landerWrapper .button-primary:hover {
  background: #0982f4;
  background: linear-gradient(65deg, #0275e1 0, #198ffd 100%);
}
.landerWrapper .button-block {
  display: flex;
}
.landerWrapper .button-block {
  display: flex;
  width: 100%;
}
@media (max-width: 640px) {
  .landerWrapper .button-wide-mobile {
    width: 100%;
    max-width: 280px;
  }
}
.landerWrapper .site-header {
  padding: 24px 0;
}
.landerWrapper .site-header-inner {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.landerWrapper .header-links {
  display: inline-flex;
}
.landerWrapper .header-links li {
  display: inline-flex;
}
.landerWrapper .header-links a:not(.button) {
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.1px;
  font-weight: 600;
  color: #8a94a7;
  text-transform: uppercase;
  text-decoration: none;
  line-height: 16px;
  padding: 8px 24px;
}
@media (min-width: 641px) {
  .landerWrapper .site-header {
    position: relative;
  }
  .landerWrapper .site-header::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 90vh;
    background: #00000087;
    transform-origin: 0;
    transform: skewY(-12deg);
    border-bottom: 2px solid hsl(186, 100%, 69%);
  }
}
.hero {
  text-align: center;
  padding-top: 48px;
  height: 80vh;
}
.hero-copy {
  position: relative;
  z-index: 1;
}
.hero-paragraph {
  color: white !important;
}
.hero-cta {
  margin-bottom: 40px;
}
.hero-figure {
  position: relative;
}
.hero-figure svg {
  width: 100%;
  height: auto;
}
.hero-figure::before,
.hero-figure::after {
  content: "";
  position: absolute;
  background-repeat: no-repeat;
  background-size: 100%;
}
.has-animations .hero-figure::before,
.has-animations .hero-figure::after {
  opacity: 0;
  transition: opacity 2s ease;
}
.anime-ready .has-animations .hero-figure::before,
.anime-ready .has-animations .hero-figure::after {
  opacity: 1;
}
.hero-figure::before {
  top: -57.8%;
  left: -1.3%;
  width: 152.84%;
  height: 178.78%;
  background-image: url("../../public/landerimages/hero-back-illustration.svg");
}
.hero-figure::after {
  top: -35.6%;
  left: 99.6%;
  width: 57.2%;
  height: 87.88%;
  background-image: url("../../public/landerimages/hero-top-illustration.svg");
}
.hero-figure-box {
  position: absolute;
  top: 0;
  will-change: transform;
}
.hero-figure-box-01,
.hero-figure-box-02,
.hero-figure-box-03,
.hero-figure-box-04,
.hero-figure-box-08,
.hero-figure-box-09 {
  overflow: hidden;
}
.hero-figure-box-01::before,
.hero-figure-box-02::before,
.hero-figure-box-03::before,
.hero-figure-box-04::before,
.hero-figure-box-08::before,
.hero-figure-box-09::before {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  transform-origin: 100% 100%;
}
.hero-figure-box-01 {
  left: 103.2%;
  top: 41.9%;
  width: 28.03%;
  height: 37.37%;
  background: linear-gradient(to left top, #00bffb, rgba(0, 191, 251, 0));
  transform: rotateZ(45deg);
}
.hero-figure-box-01::before {
  background: linear-gradient(to left, #15181d 0%, rgba(21, 24, 29, 0) 60%);
  transform: rotateZ(45deg) scale(1.5);
}
.hero-figure-box-02 {
  left: 61.3%;
  top: 64.1%;
  width: 37.87%;
  height: 50.5%;
  background: linear-gradient(to left top, #0270d7, #0270d7);
  transform: rotateZ(-45deg);
}
.hero-figure-box-02::before {
  background: linear-gradient(to top, #15181d 0%, rgba(21, 24, 29, 0) 60%);
  transform: rotateZ(-45deg) scale(1.5);
}
.hero-figure-box-03 {
  left: 87.7%;
  top: -56.8%;
  width: 56.81%;
  height: 75.75%;
  background: linear-gradient(to left top, #00bffb, rgba(0, 191, 251, 0));
}
.hero-figure-box-03::before {
  background: linear-gradient(to left, #15181d 0%, rgba(21, 24, 29, 0) 60%);
  transform: rotateZ(45deg) scale(1.5);
}
.hero-figure-box-04 {
  left: 54.9%;
  top: -8%;
  width: 45.45%;
  height: 60.6%;
  background: linear-gradient(to left top, #0270d7, rgba(2, 112, 215, 0));
  transform: rotateZ(-135deg);
}
.hero-figure-box-04::before {
  background: linear-gradient(
    to top,
    rgba(255, 255, 255, 0.24) 0%,
    rgba(255, 255, 255, 0) 60%
  );
  transform: rotateZ(-45deg) scale(1.5);
}

.hero-figure-box-06,
.hero-figure-box-07 {
  background: #242830;
  box-shadow: -20px 32px 64px rgba(0, 0, 0, 0.25);
}
.hero-figure-box-05 {
  background: #242830;
  box-shadow: -20px 32px 64px rgba(0, 0, 0, 0.25);
}
.hero-figure-box-05 {
  left: 17.4%;
  top: 13.3%;
  width: 64%;
  height: 73.7%;
  transform: perspective(500px) rotateY(-15deg) rotateX(8deg) rotateZ(-1deg);
}
.hero-figure-box-06 {
  left: 65.5%;
  top: 6.3%;
  width: 30.3%;
  height: 40.4%;
  transform: rotateZ(20deg);
}
.hero-figure-box-07 {
  left: 1.9%;
  top: 42.4%;
  width: 12.12%;
  height: 16.16%;
  transform: rotateZ(20deg);
}
.hero-figure-box-08 {
  left: 27.1%;
  top: 81.6%;
  width: 19.51%;
  height: 26.01%;
  background: #0270d7;
  transform: rotateZ(-22deg);
}
.hero-figure-box-08::before {
  background: linear-gradient(
    to left,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 0.48) 100%
  );
  transform: rotateZ(45deg) scale(1.5);
}
.hero-figure-box-09 {
  left: 42.6%;
  top: -17.9%;
  width: 6.63%;
  height: 8.83%;
  background: #00bffb;
  transform: rotateZ(-52deg);
}
.hero-figure-box-09::before {
  background: linear-gradient(
    to left,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 0.64) 100%
  );
  transform: rotateZ(45deg) scale(1.5);
}
.hero-figure-box-10 {
  left: -3.8%;
  top: 4.3%;
  width: 3.03%;
  height: 4.04%;
  background: rgba(0, 191, 251, 0.32);
  transform: rotateZ(-50deg);
}
@media (max-width: 640px) {
  .hero {
    height: auto !important;
  }
  .features.section {
    margin-top: 5rem;
  }
  .hero-cta .button {
    display: flex;
  }
  .hero-cta .button + .button {
    margin-top: 16px;
  }
  .hero-illustration {
    display: none !important;
  }
  .hero-figure::after,
  .hero-figure-box-03,
  .hero-figure-box-04,
  .hero-figure-box-09 {
    display: none;
  }
  .glowing-btn {
    font-size: 0.8em !important;
    padding: 0.45em 1em !important;
    background-color: var(--glow-color) !important;
    box-shadow: 0 0 1em 0.2em var(--glow-color) !important;
    transition: opacity 100ms linear !important;
    color: rgba(0, 0, 0, 0.8) !important;
    text-shadow: none;
    animation: none !important;
    margin-bottom: 5rem !important;
  }
  .glowing-txt {
    float: none !important;
  }

  .landerWrapper .glowing-btn .glowing-txt {
    animation: none !important;
  }

  .landerWrapper .glowing-btn .faulty-letter {
    animation: none !important;
    text-shadow: none !important;
    opacity: 1 !important;
  }
  .landerWrapper .glowing-btn .faulty-letter2 {
    animation: none !important;
    text-shadow: none !important;
    opacity: 1 !important;
  }
  .hero-paragraph {
    font-size: 1rem !important;
    text-align: center;
    width: 100% !important;
  }

  .line-1 {
    border-right: none !important;
  }
  .aqua-button {
    font-size: 1rem !important;
    margin: 0rem 1rem 0rem 0rem !important;
  }
  .landerWrapper .section-inner {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
  }
  .landerWrapper .section-inner .contacttitle {
    width: 100% !important;
  }
  .landerWrapper .section-inner .contacttitleimg {
    display: none !important;
  }
  .landerWrapper .section-inner .contacttitleform {
    width: 100% !important;
  }
  .section-title {
    font-family: unset !important;
    font-size: 1.2rem !important;
  }
  .section-paragraph {
    font-size: 1rem !important;
  }
  .landerWrapper .styled-input label {
    font-size: 1rem;
  }
}
@media (min-width: 641px) {
  .footer-brand {
    display: flex;
    flex-direction: column;
  }
  .hero-paragraph {
    padding: 0.7rem 0rem;
    font-size: xx-large !important;
  }
  .landerWrapper .labellander {
    margin-left: 2rem !important;
  }
  .hero-illustration {
    display: none !important;
  }
  .hero {
    text-align: left;
    padding-top: 5rem;
  }
  /* .anime-element{
		display: none !important;
	}
	.hero-figure{
		display: none !important;
	} */
  .hero-inner {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .hero-copy {
    padding-right: 64px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
  }
  .hero-cta {
    margin: 0;
  }
  .hero-cta .button {
    min-width: 170px;
  }
  .hero-cta .button:first-child {
    margin-right: 16px;
  }
  .hero-figure svg {
    width: auto;
  }
  .landerWrapper .aqua-button {
    font-size: 1.2rem !important;
    margin: 0rem 2rem 0rem 0rem !important;
  }
}
.section-title {
  font-family: unset;
  font-size: 1rem;
}
.features-wrap {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  margin-right: -32px;
  margin-left: -32px;
}
.features-wrap:first-of-type {
  margin-top: -16px;
}
.features-wrap:last-of-type {
  margin-bottom: -16px;
}
.landerWrapper .feature {
  padding: 16px 32px;
  width: 380px;
  max-width: 380px;
  flex-grow: 1;
}
.landerWrapper .feature-inner {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}
.landerWrapper .feature-icon {
  display: flex;
  justify-content: center;
}
@media (min-width: 641px) {
  .landerWrapper .features-wrap:first-of-type {
    margin-top: -24px;
  }
  .landerWrapper .features-wrap:last-of-type {
    margin-bottom: -24px;
  }
  .landerWrapper .feature {
    padding: 32px 32px;
    margin: 1rem 0rem !important;
    border-radius: 0rem 5rem 0rem 5rem;
    background-color: #00000063;

  }
}
.pricing-header {
  margin-bottom: 48px;
}
.pricing-tables-wrap {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-right: -12px;
  margin-left: -12px;
}
.pricing-tables-wrap:first-child {
  margin-top: -12px;
}
.pricing-tables-wrap:last-child {
  margin-bottom: -12px;
}
.pricing-table {
  position: relative;
  padding: 12px;
  width: 368px;
  max-width: 368px;
  flex-grow: 1;
}
.pricing-table::before {
  content: "";
  position: absolute;
  left: 50%;
  width: 200%;
  max-width: 200%;
  height: 435px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
  bottom: 18.8%;
  transform: translateX(-50%);
  background-image: url("../../public/landerimages/pricing-illustration.svg");
}
.is-boxed {
  background: #242830;
}
.pricing-table-header,
.pricing-table-features-title,
.pricing-table-features li {
  border-bottom: 1px solid rgba(138, 148, 167, 0.24);
}
.pricing-table-inner {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  background: #2c3039;
  padding: 24px;
  height: 100%;
}
.pricing-table-inner > * {
  position: relative;
  width: 100%;
}
.pricing-table-inner::before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  box-shadow: 0 24px 48px rgba(21, 24, 29, 0.24);
  mix-blend-mode: multiply;
}
.pricing-table-price-currency {
  color: #8a94a7;
}
.pricing-table-features-title {
  color: #fff;
  font-weight: 700;
}
.pricing-table-features li {
  display: flex;
  align-items: center;
  padding: 14px 0;
}
.pricing-table-features li::before {
  content: "";
  width: 16px;
  height: 12px;
  margin-right: 16px;
  background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTYiIGhlaWdodD0iMTIiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZD0iTTUuNiA2LjRMMS42IDQgMCA1LjYgNS42IDEyIDE2IDEuNiAxNC40IDB6IiBmaWxsPSIjMDJDQkIxIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiLz48L3N2Zz4=);
  background-repeat: no-repeat;
}
.pricing-table-cta {
  align-self: flex-end;
}
@media (min-width: 641px) {
  .pricing .section-paragraph {
    margin-left: 90px;
    margin-right: 90px;
    padding: 1rem;
    border-radius: 0.5rem;
  }
  .pricing-header {
    margin-bottom: 52px;
    background: #15181d91;
    padding: 1rem;
  }
}
.cta {
  text-align: center;
}
.cta .section-inner {
  padding: 48px 16px !important;
}
.cta .section-title {
  margin-bottom: 40px;
}
.cta-inner {
  position: relative;
  background: #15181d91;
  overflow: hidden;
}
.cta-inner::before {
  content: "";
  position: absolute;
  right: 98px;
  top: -117px;
  width: 160px;
  height: 187px;
  background-image: url("../../public/landerimages/cta-illustration.svg");
  background-repeat: no-repeat;
}
.cta-inner > * {
  position: relative;
}
@media (min-width: 641px) {
  .cta {
    text-align: left;
  }

  .cta .section-title {
    margin-bottom: 0;
    padding-right: 24px;
  }
  .cta-inner {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}
.is-boxed {
  background: #242830;
}
.body-wrap {
  background: url("../icons/bgfirst.jpg");
  background-size: cover;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}
.boxed-container {
  max-width: 1440px;
  margin: 0 auto;
  box-shadow: 0 24px 48px rgba(21, 24, 29, 0.24);
  mix-blend-mode: multiply;
  mix-blend-mode: normal;
}
.landerWrapper main {
  flex: 1 0 auto;
}
.landerWrapper .section-inner {
  position: relative;
  padding-top: 0px;
  padding-bottom: 48px;
}
@media (min-width: 641px) {
  .landerWrapper .section-inner {
    padding-top: 0px;
    padding-bottom: 88px;
  }
}
.landerWrapper .site-footer {
  font-size: 14px;
  line-height: 22px;
  letter-spacing: 0px;
}
.landerWrapper .site-footer a {
  color: #8a94a7;
  text-decoration: none;
}
.landerWrapper .site-footer a:hover,
.landerWrapper .site-footer a:active {
  text-decoration: underline;
}
.landerWrapper .site-footer-inner {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  padding-top: 48px;
  padding-bottom: 48px;
}
.landerWrapper .footer-brand,
.landerWrapper .footer-links,
.landerWrapper .footer-social-links,
.landerWrapper .footer-copyright {
  flex: none;
  width: 100%;
  display: inline-flex;
  justify-content: center;
}
.landerWrapper .footer-brand,
.landerWrapper .footer-links,
.landerWrapper .footer-social-links {
  margin-bottom: 24px;
}
.landerWrapper .footer-social-links li {
  display: inline-flex;
}
.landerWrapper .footer-social-links li + li {
  margin-left: 16px;
}
.landerWrapper .footer-social-links li a {
  padding: 8px;
}
.landerWrapper .footer-links li + li {
  margin-left: 24px;
}
@media (min-width: 641px) {
  .landerWrapper .site-footer-inner {
    justify-content: space-between;
    padding-top: 64px;
    padding-bottom: 64px;
  }
  .landerWrapper .footer-brand,
  .landerWrapper .footer-links,
  .landerWrapper .footer-social-links,
  .landerWrapper .footer-copyright {
    flex: 50%;
  }
  .landerWrapper .footer-brand,
  .landerWrapper .footer-copyright {
    justify-content: flex-start;
  }
  .landerWrapper .footer-links,
  .landerWrapper .footer-social-links {
    justify-content: flex-end;
  }
  .landerWrapper .footer-links {
    order: 1;
    margin-bottom: 0;
  }
}
.landerWrapper .centerer {
  position: sticky;
  left: 0;
  width: fit-content;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.landerWrapper .glowing-btn {
  margin-bottom: 8rem;
  position: relative;
  color: var(--glow-color);
  cursor: pointer;
  padding: 0.55em 1em;
  border: 0.15em solid var(--glow-color);
  border-radius: 0.45em;
  background: none;
  perspective: 2em;
  font-family: "Raleway", sans-serif;
  font-size: 2em;
  font-weight: 900;
  letter-spacing: 1em;
  box-shadow: inset 0 0 0.25em var(--glow-color), 0 0 0.25em var(--glow-color);
  animation: border-flicker 2s linear infinite;
  z-index: 5;
}

.landerWrapper .glowing-btn2 {
  margin-bottom: 3rem;
  position: relative;
  color: black;
  padding: 0.35em 1em;
  border-radius: 0.45em;
  background: none;
  font-family: "Raleway", sans-serif;
  font-size: 2em;
  font-weight: 900;
  letter-spacing: 1em;
  background: white;
  box-shadow: 0 0 0.15em 0.1em rgb(255, 255, 255);
  opacity: 1; /* Ensure it remains visible */
  z-index: 5;
}

.landerWrapper .glowing-txt {
  float: left;
  margin-right: -0.8em;
  animation: text-flicker 3s linear infinite;
}

.landerWrapper .faulty-letter {
  opacity: 0.5;
  animation: faulty-flicker 2s linear infinite;
}
.landerWrapper .faulty-letter2 {
  opacity: 0.5;
  animation: faulty-flicker 4s linear infinite;
}

.landerWrapper .glowing-btn::before {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  opacity: 0.7;
  filter: blur(1em);
  transform: translateY(100%) rotateX(75deg) scale(0.9, 0.2);
  background: var(--glow-color);
  pointer-events: none;
}

.landerWrapper .glowing-btn::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0;
  z-index: -1;
  background-color: var(--glow-color);
  box-shadow: 0 0 0.2em 0.1em var(--glow-color);
  transition: opacity 100ms linear;
}

.landerWrapper .glowing-btn:hover {
  color: rgba(0, 0, 0, 0.8);
  text-shadow: none;
  animation: none;
}

.landerWrapper .glowing-btn:hover .glowing-txt {
  animation: none;
}

.landerWrapper .glowing-btn:hover .faulty-letter {
  animation: none;
  text-shadow: none;
  opacity: 1;
}
.landerWrapper .glowing-btn:hover .faulty-letter2 {
  animation: none;
  text-shadow: none;
  opacity: 1;
}
.glowing-btn2.hovered {
  background: white;
}

.landerWrapper .glowing-btn:hover::before {
  filter: blur(1.5em);
  opacity: 1;
}

.landerWrapper .glowing-btn:hover::after {
  opacity: 1;
}

@keyframes faulty-flicker {
  0%,
  2% {
    opacity: 0.1;
  }
  4%,
  19% {
    opacity: 0.5;
  }
  21% {
    opacity: 0.1;
  }
  23%,
  80% {
    opacity: 1;
  }
  83% {
    opacity: 0.4;
  }
}

@keyframes text-flicker {
  0%,
  8%,
  12% {
    opacity: 0.1;
  }
  2%,
  9%,
  20%,
  30% {
    opacity: 1;
  }
  25% {
    opacity: 0.3;
  }
  70% {
    opacity: 0.7;
  }
  72% {
    opacity: 0.2;
  }
  77%,
  100% {
    opacity: 0.9;
  }
}

@keyframes border-flicker {
  0%,
  4% {
    opacity: 0.1;
  }
  2%,
  8%,
  70%,
  100% {
    opacity: 1;
  }
}

.landerWrapper .glowing-txt2 {
  float: left;
  margin-right: -0.8em;
}

.line-1 {
  position: relative;
  top: 50%;
  width: 24em;
  border-right: 2px solid rgba(255, 255, 255, 0.75);
  white-space: nowrap;
  overflow: hidden;
  transform: translateY(-50%);
}

/* Animation */
.anim-typewriter {
  animation: typewriter 10s steps(40) 1s infinite normal both,
    blinkTextCursor 500ms steps(40) infinite normal;
}
@keyframes typewriter {
  0% {
    width: 0;
  }
  20% {
    width: 18em;
  }
  90% {
    width: 18em;
  }
  100% {
    width: 0em;
  }
}
@keyframes blinkTextCursor {
  from {
    border-right-color: rgba(255, 255, 255, 0.75);
  }
  to {
    border-right-color: transparent;
  }
}

.landerWrapper .aqua-button {
  font-size: 1.1rem;
  color: hsla(190, 70%, 10%, 0.9) !important;
  font-weight: 500 !important;
  letter-spacing: -0.025em !important;
  background-color: hsl(190, 50%, 90%) !important;
  background-image: linear-gradient(
    180deg,
    hsl(186, 100%, 69%) 45%,
    hsla(250, 60%, 95%, 0.5) 100%
  ) !important;
  background-repeat: no-repeat !important;
  background-position: center 45% !important;
  background-size: 100% 200% !important;
  padding: 0.5rem 1rem !important;
  border-radius: 5rem !important;
  border: none !important;
  box-shadow: 0 -0.5em 0.5em transparent, 0 0.5em 0.5em transparent,
    0 0.25em 0.3em -0.2em hsla(190, 50%, 50%, 0.46),
    0 0.25em 0.75em hsla(190, 40%, 40%, 0.3) !important;
  position: relative !important;
  transition: all 0.5s ease !important;
  outline: none !important;
  margin: 0rem 1rem 0rem 0rem;
}

.landerWrapper .aqua-button::before,
.landerWrapper .aqua-button::after {
  content: "" !important;
  inset: 0 !important;
  position: absolute !important;
  border-radius: 5rem !important;
}

.landerWrapper .aqua-button::before {
  background-image: radial-gradient(
      ellipse,
      hsla(190, 100%, 90%, 0.8) 20%,
      transparent 50%,
      transparent 200%
    ),
    linear-gradient(
      90deg,
      #404040 -10%,
      transparent 30%,
      transparent 70%,
      #404040 110%
    ) !important;
  box-shadow: inset 0 0.25em 0.75em rgba(0, 0, 0, 0.8),
    inset 0 -0.05em 0.2em rgba(255, 255, 255, 0.4),
    inset 0 -1px 3px hsla(190, 80%, 50%, 0.75) !important;
  background-blend-mode: overlay !important;
  background-repeat: no-repeat !important;
  background-size: 200% 80%, cover !important;
  background-position: center 220% !important;
  mix-blend-mode: overlay !important;
  filter: blur(2px) !important;
}

.landerWrapper .aqua-button::after {
  background: linear-gradient(
    180deg,
    hsla(250, 100%, 90%, 0.9),
    hsla(250, 80%, 50%, 0.75) 40%,
    transparent 80%
  ) !important;
  top: 0.075em !important;
  left: 0.75em !important;
  right: 0.75em !important;
  bottom: 1.4em !important;
  filter: blur(0px) !important;
  mix-blend-mode: screen !important;
}

.landerWrapper .aqua-button:hover,
.landerWrapper .aqua-button:active,
.landerWrapper .aqua-button:focus {
  outline: none !important;
  box-shadow: 0 -0.2em 1em hsla(250, 70%, 80%, 0.3),
    0 0.5em 1.5em hsla(190, 70%, 80%, 0.5),
    0 0.25em 0.3em -0.2em hsl(190, 90%, 70%),
    0 0.25em 0.5em hsla(190, 20%, 30%, 0.2),
    inset 0 -2px 2px rgba(255, 255, 255, 0.2) !important;
  background-position: center 44% !important;
}

/*Contact form*/

.landerWrapper input:focus ~ label,
.landerWrapper textarea:focus ~ label,
.landerWrapper input:valid ~ label,
.landerWrapper textarea:valid ~ label {
  font-size: 0.65em;
  color: #999;
  top: -25px;
  -webkit-transition: all 0.225s ease;
  transition: all 0.225s ease;
}

.landerWrapper .styled-input {
  float: left;
  width: 100%;
  margin: 1rem 0;
  position: relative;
  border-radius: 4px;
}

@media only screen and (max-width: 768px) {
  .landerWrapper .styled-input {
    width: 100%;
  }
}

.landerWrapper .styled-input label {
  color: #ffffff8c;
  padding: 25px;
  position: absolute;
  top: 0px;
  left: 0;
  -webkit-transition: all 0.25s ease;
  transition: all 0.25s ease;
  pointer-events: none;
  font-size: 1.1rem;
}

.landerWrapper .styled-input.wide {
  width: 100%;
}

.landerWrapper input,
.landerWrapper textarea {
  padding: 25px;
  border: 0;
  width: 100%;
  font-size: 1.3rem;
  background-color: #2d3039b5;
  color: white;
  border-radius: 4px;
}

.landerWrapper input:focus,
.landerWrapper textarea:focus {
  outline: 0;
}

.landerWrapper input:focus ~ span,
.landerWrapper textarea:focus ~ span {
  width: 100%;
  -webkit-transition: all 0.075s ease;
  transition: all 0.075s ease;
}

.landerWrapper textarea {
  width: 100%;
  min-height: 15em;
}

.landerWrapper .input-container {
  width: 650px;
  max-width: 100%;
  margin: 20px auto 25px auto;
}

@media (max-width: 768px) {
  .landerWrapper .submit-btn {
    width: 100%;
    float: none;
    text-align: center;
  }
}

.landerWrapper input[type="checkbox"] + label {
  color: #ccc;
  font-style: italic;
}

.landerWrapper input[type="checkbox"]:checked + label {
  color: #f00;
  font-style: normal;
}
