/* Main Panel */
.main-panel {
  padding: 20px;
  background-color: transparent;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
}

/* Content Wrapper */
.content-wrapper {
  margin: 20px;
}

.headrerCard h4.text-primary {
  margin: 0 !important;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px,
    rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px,
    rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
}

.dashboarder {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.img {
  display: flex;
  justify-content: center;
  align-items: center;
}

.text-body-secondary {
  font-weight: 300;
  color: white !important;
}
.clearfix {
  justify-content: center;
  width: 100%;
  margin-left: 1rem;
}
.titleer {
  display: flex;
  justify-content: space-evenly;
  flex-direction: column;
  align-items: baseline;
  width: 50%;
}
.headrerCard {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.float-right {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  width: 100%;
}
.float-right .display-6 {
  font-size: 2.2rem;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

figure {
  margin: 0;
}
.blockquote {
  margin-bottom: 0;
}
.blockquote-footer {
  margin-bottom: 0;
  margin-top: 0.5rem;
  color: white !important;
}
/* Icon Styles */
.icon-lg {
  font-size: 40px;
}

.mdi-file-box {
  color: #ff9800;
}

.mdi-send {
  color: #4caf50;
}

.mdi-inbox-arrow-down {
  color: #2196f3;
}

/* Card Header Styles */
.card-statistics .clearfix {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.card-statistics .float-left {
  margin-right: 15px;
}

.card-statistics .float-right {
  text-align: right;
}

.card-statistics p {
  font-size: 14px;
  color: #757575;
  margin: 0;
}

.card-statistics h3 {
  font-size: 28px;
  font-weight: bold;
  margin: 0;
  color: #333;
}

/* Section Titles */
h4.text-primary {
  font-size: 22px;
  color: #1a73e8;
  font-weight: 600;
  margin-bottom: 20px;
}

/* Table Styles */
.table-responsive {
  margin-top: 20px;
}

.date-picker {
  width: 100%;
  max-width: 300px;
  margin-bottom: 20px;
}

.date-picker input {
  width: 100%;
  padding: 10px;
  border-radius: 4px;
  border: 1px solid #ccc;
}

.table-responsive {
  min-height: 200px; /* Adjust this value to match the height of 5 entries */
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  transition: all 0.3s ease-in-out;
}
.table {
  background-color: transparent;
  color: #fff;
}

.table th,
.table td {
  border-color: #555;
}

.table th {
  background-color: #ffffff00;
  color: #fff;
  font-weight: bold;
}

.table td {
  background-color: transparent;
  color: #fff;
}

.table tr:nth-child(even) {
  background-color: #e7e7e72e;
}

.table-placeholder {
  height: 50px;
  background-color: transparent; /* Keep placeholders transparent */
}

.table-with-data td {
  background-color: #e7e7e72e; /* White background for cells with data */
  color: #ffffff; /* Black text for cells with data */
  border-color: #000;
  font-size: large;
}

.table-with-data th {
  background-color: #ffffff; /* White background for headers when table has data */
  color: #000; /* Black text for headers when table has data */
  border-color: #000;
  font-size: large;
}
.table-with-data{
  border-radius: 5rem;
}
.table-with-data tr:nth-child(even) {
  background-color: #e7e7e72e;
}


/* Add this to your App.css or similar stylesheet */
.modal-backdrop.show {
  opacity: 0.5; /* Adjust the opacity as needed */
}

table.dataTable {
  transition: all 0.3s ease-in-out;
}

table.dataTable tbody {
  min-height: 160px; /* Adjust this value to match the height of 4 entries */
  transition: all 0.3s ease-in-out;
}

.table-responsive.no-entries {
  min-height: auto;
}

.table tbody tr {
  height: 50px; /* Adjust row height as needed */
}

.table th,
.table td {
  text-align: center;
  vertical-align: middle;
  padding: 15px;
}

.table img {
  border-radius: 5px;
}

.pagination-container {
  display: flex;
  justify-content: center;
  margin-top: 20px;
  background-color: transparent;
}

.pagination {
  display: flex;
  list-style-type: none;
  padding: 0.2rem;
}

.pagination li {
  margin: 0 5px;
}
.entries-selector label{
    width: 9rem;
}
.pagination li a {
  display: block;
  padding: 10px 15px;
  color: #ffffff;
  text-decoration: none;
  background-color: transparent;
  border-radius: 3px;
  transition: background-color 0.1s;
}

.react-datepicker__input-container input {
  background-color: transparent;
  color: white;
}

.pagination li a:hover {
  background-color: rgba(255, 255, 255, 0.21);
}

.pagination li.active a {
  background-color: rgba(255, 255, 255, 0.21);
}

.pagination li.disabled a {
  color: #aaa;
  pointer-events: none;
}

/* DatePicker Styles */
.react-datepicker-wrapper {
  display: inline-block;
}

.react-datepicker__input-container input {
  width: 100%;
  padding: 10px;
  border: 1px solid #d1d1d1;
  border-radius: 5px;
  font-size: 16px;
  margin-top: 10px;
}

.react-datepicker__input-container input:focus {
  border-color: #1a73e8;
  outline: none;
}

/* styles.module.css */
.form-select {
  background-color: black !important;
  color: white !important;
  border: 1px solid #333;
  width: 7rem;
}

.entries-selector{
    display: flex;
    justify-content: space-evenly;
    align-items: center;
}
.profilepIc {
  width: 11rem;
  border-radius: 50%;
  transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
  box-shadow: 0 0 10px rgba(255, 255, 255, 0.8);
}

.profilepIc:hover {
  transform: scale(1.05); /* Slightly increase the size on hover */
  box-shadow: 0 0 15px rgba(255, 255, 255, 0.8); /* Stronger shadow on hover */
}
