@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

::-webkit-scrollbar {
  width: 4px; /* width of the scrollbar */
}

/* Track */
::-webkit-scrollbar-track {
  background: transparent;
  /* color of the track */
}

/* Handle */
::-webkit-scrollbar-thumb {
  background-color: #00eeff; /* color of the scrollbar handle */
  /* box-shadow: inset 0 0 6px rgba(255, 251, 0, 0.269); */
  height: 10px !important;
}

.form-control::placeholder {
  color: white;
}

#btn1 {
  width: 50%;
  padding: 0.3rem;
  border-right: 2px solid #e9acff;
}
#btn1:hover {
  background: linear-gradient(to right, #833ab4, #2720a1, #121120);
}

#btn2 {
  width: 50%;
  padding: 0.3rem;
  border-left: 2px solid #e9acff;
}
#btn2:hover {
  background: linear-gradient(to right, #833ab4, #2720a1, #121120);
}

@keyframes move {
  100% {
    transform: translate3d(0, 0, 1px) rotate(360deg);
  }
}

@media only screen and (max-width: 600px) {
  .background {
    width: 100vw;
    min-height: 100vh;
    height: 100%;
    position: absolute;
    overflow-y: auto;
    background-image: url("../../icons/backgroundAppMob.jpg");
    background-size: cover;
    top: 0;
    left: 0;
  }

  #floaterlog1 {
    padding: 0rem;
    width: 100%;
    display: flex;
    justify-content: center;
  }
  #floaterlog2 {
    padding: 0rem;
    width: 100%;
    display: flex;
    justify-content: center;
  }
  #floatersign1 {
    background: transparent;
    border: none;
    color: black;
    display: flex;
    justify-content: center;
  }
  #floatersign2 {
    background: transparent;
    border: none;
    color: black;
    display: flex;
    justify-content: center;
  }
  #floatersign3 {
    background: transparent;
    border: none;
    color: black;
    display: flex;
    justify-content: center;
  }
  #floatersign4 {
    background: transparent;
    border: none;
    color: black;
    display: flex;
    justify-content: center;
  }
  #floaterlog1 label{
    padding: 1rem 1rem !important;
    margin-left: 1rem !important;
  }
  #floaterlog1 input:focus{
    outline:0;
  }
  #floaterlog2 label{
    padding: 1rem 1rem !important;
    margin-left: 1rem !important;
  }
  #floatersign1 label{
    padding: 1rem 1rem !important;
    margin-left: 1rem !important;
  }
  #floatersign2 label{
    padding: 1rem 1rem !important;
    margin-left: 1rem !important;
  }
  #floatersign3 label{
    padding: 1rem 1rem !important;
    margin-left: 1rem !important;
  }
  #floatersign4 label{
    padding: 1rem 1rem !important;
    margin-left: 1rem !important;
  }
  .logupimg {
    padding: 2rem 0rem;
    width: 15rem !important;
  }
  .imgCrp .imgCrpdiv{
    width: 90%;
  }
}
.whitebtn:hover{
  color: #000000 !important;
}
.logsign .form-floating > input + label:after {
  background: #2c3039 !important;
}

@media only screen and (min-width: 601px) and (max-width: 1024px) {
  #floaterlog1 label{
    padding: 1rem 1rem !important;
    margin-left: 1.5rem !important;
  }
  #floaterlog1 input:focus{
    outline:0;
  }
  #floaterlog2 label{
    padding: 1rem 1rem !important;
    margin-left: 1.5rem !important;
  }
  #floatersign1 label{
    padding: 1rem 1rem !important;
    margin-left: 1.5rem !important;
  }
  #floatersign2 label{
    padding: 1rem 1rem !important;
    margin-left: 1.5rem !important;
  }
  #floatersign3 label{
    padding: 1rem 1rem !important;
    margin-left: 1.5rem !important;
  }
  #floatersign4 label{
    padding: 1rem 1rem !important;
    margin-left: 1.5rem !important;
  }
  .background {
    width: 100vw;
    min-height: 100vh;
    height: 100%;
    position: absolute;
    background-image: url("../../icons/backgroundAppDesk.jpg");
    background-size: cover;
    top: 0;
    left: 0;
    overflow-y: auto;
  }
  .gradient-form {
    min-height: 100vh;
  }

  #floaterlog1 {
    padding: 0rem;
    width: 100%;
    display: flex;
    justify-content: center;
  }
  #floaterlog2 {
    padding: 0rem;
    width: 100%;
    display: flex;
    justify-content: center;
  }
  #floatersign1 {
    background: transparent;
    border: none;
    color: black;
    display: flex;
    justify-content: center;
  }
  #floatersign2 {
    background: transparent;
    border: none;
    color: black;
    display: flex;
    justify-content: center;
  }
  #floatersign3 {
    background: transparent;
    border: none;
    color: black;
    display: flex;
    justify-content: center;
  }
  #floatersign4 {
    background: transparent;
    border: none;
    color: black;
    display: flex;
    justify-content: center;
  }
}

@media only screen and (min-width: 1025px) {
  .background {
    background-image: url("../../icons/backgroundAppDesk.jpg");
    background-size: cover;
    position: absolute;
    width: 100vw;
    height: 100%;
    min-height: 100vh;
    top: 0;
    left: 0;
    overflow-y: auto;
  }

  #floaterlog1 {
    padding: 0rem;
    width: 100%;
    display: flex;
    justify-content: center;
  }
  #floaterlog2 {
    padding: 0rem;
    width: 100%;
    display: flex;
    justify-content: center;
  }
  #floatersign1 {
    background: transparent;
    border: none;
    color: black;
  }
  #floatersign2 {
    background: transparent;
    border: none;
    color: black;
  }
  #floatersign3 {
    background: transparent;
    border: none;
    color: black;
  }
  #floatersign4 {
    background: transparent;
    border: none;
    color: black;
  }
}

.modalbox.success,
.modalbox.error {
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  border-radius: 2px;
  background: #fff;
  padding: 25px 25px 15px;
  text-align: center;
}
.modalbox.success.animate .icon,
.modalbox.error.animate .icon {
  -webkit-animation: fall-in 0.75s;
  -moz-animation: fall-in 0.75s;
  -o-animation: fall-in 0.75s;
  animation: fall-in 0.75s;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
}
.modalbox.success h1,
.modalbox.error h1 {
  font-family: "Montserrat", sans-serif;
}
.modalbox.success p,
.modalbox.error p {
  font-family: "Open Sans", sans-serif;
}
.modalbox.success button,
.modalbox.error button,
.modalbox.success button:active,
.modalbox.error button:active,
.modalbox.success button:focus,
.modalbox.error button:focus {
  -webkit-transition: all 0.1s ease-in-out;
  transition: all 0.1s ease-in-out;
  -webkit-border-radius: 30px;
  -moz-border-radius: 30px;
  border-radius: 30px;
  margin-top: 15px;
  width: 80%;
  background: transparent;
  color: #4caf50;
  border-color: #4caf50;
  outline: none;
}
.modalbox.success button:hover,
.modalbox.error button:hover,
.modalbox.success button:active:hover,
.modalbox.error button:active:hover,
.modalbox.success button:focus:hover,
.modalbox.error button:focus:hover {
  color: #fff;
  background: #4caf50;
  border-color: transparent;
}
.modalbox.success .icon,
.modalbox.error .icon {
  position: relative;
  margin: 0 auto;
  margin-top: -75px;
  background: #4caf50;
  height: 100px;
  width: 100px;
  border-radius: 50%;
}
.modalbox.success .icon span,
.modalbox.error .icon span {
  position: absolute;
  font-size: 4em;
  color: #fff;
  text-align: center;
  padding-top: 20px;
}
.modalbox.error button,
.modalbox.error button:active,
.modalbox.error button:focus {
  color: #f44336;
  border-color: #f44336;
}
.modalbox.error button:hover,
.modalbox.error button:active:hover,
.modalbox.error button:focus:hover {
  color: #fff;
  background: #f44336;
}
.modalbox.error .icon {
  background: #f44336;
}
.modalbox.error .icon span {
  padding-top: 25px;
}
.center {
  float: none;
  margin-left: auto;
  margin-right: auto;
  /* stupid browser compat. smh */
}
.center .change {
  clear: both;
  display: block;
  font-size: 10px;
  color: #ccc;
  margin-top: 10px;
}
@-webkit-keyframes fall-in {
  0% {
    -ms-transform: scale(3, 3);
    -webkit-transform: scale(3, 3);
    transform: scale(3, 3);
    opacity: 0;
  }
  50% {
    -ms-transform: scale(1, 1);
    -webkit-transform: scale(1, 1);
    transform: scale(1, 1);
    opacity: 1;
  }
  60% {
    -ms-transform: scale(1.1, 1.1);
    -webkit-transform: scale(1.1, 1.1);
    transform: scale(1.1, 1.1);
  }
  100% {
    -ms-transform: scale(1, 1);
    -webkit-transform: scale(1, 1);
    transform: scale(1, 1);
  }
}
@-moz-keyframes fall-in {
  0% {
    -ms-transform: scale(3, 3);
    -webkit-transform: scale(3, 3);
    transform: scale(3, 3);
    opacity: 0;
  }
  50% {
    -ms-transform: scale(1, 1);
    -webkit-transform: scale(1, 1);
    transform: scale(1, 1);
    opacity: 1;
  }
  60% {
    -ms-transform: scale(1.1, 1.1);
    -webkit-transform: scale(1.1, 1.1);
    transform: scale(1.1, 1.1);
  }
  100% {
    -ms-transform: scale(1, 1);
    -webkit-transform: scale(1, 1);
    transform: scale(1, 1);
  }
}
@-o-keyframes fall-in {
  0% {
    -ms-transform: scale(3, 3);
    -webkit-transform: scale(3, 3);
    transform: scale(3, 3);
    opacity: 0;
  }
  50% {
    -ms-transform: scale(1, 1);
    -webkit-transform: scale(1, 1);
    transform: scale(1, 1);
    opacity: 1;
  }
  60% {
    -ms-transform: scale(1.1, 1.1);
    -webkit-transform: scale(1.1, 1.1);
    transform: scale(1.1, 1.1);
  }
  100% {
    -ms-transform: scale(1, 1);
    -webkit-transform: scale(1, 1);
    transform: scale(1, 1);
  }
}
@-webkit-keyframes plunge {
  0% {
    margin-top: -100%;
  }
  100% {
    margin-top: 25%;
  }
}
@-moz-keyframes plunge {
  0% {
    margin-top: -100%;
  }
  100% {
    margin-top: 25%;
  }
}
@-o-keyframes plunge {
  0% {
    margin-top: -100%;
  }
  100% {
    margin-top: 25%;
  }
}
@-moz-keyframes fall-in {
  0% {
    -ms-transform: scale(3, 3);
    -webkit-transform: scale(3, 3);
    transform: scale(3, 3);
    opacity: 0;
  }
  50% {
    -ms-transform: scale(1, 1);
    -webkit-transform: scale(1, 1);
    transform: scale(1, 1);
    opacity: 1;
  }
  60% {
    -ms-transform: scale(1.1, 1.1);
    -webkit-transform: scale(1.1, 1.1);
    transform: scale(1.1, 1.1);
  }
  100% {
    -ms-transform: scale(1, 1);
    -webkit-transform: scale(1, 1);
    transform: scale(1, 1);
  }
}
@-webkit-keyframes fall-in {
  0% {
    -ms-transform: scale(3, 3);
    -webkit-transform: scale(3, 3);
    transform: scale(3, 3);
    opacity: 0;
  }
  50% {
    -ms-transform: scale(1, 1);
    -webkit-transform: scale(1, 1);
    transform: scale(1, 1);
    opacity: 1;
  }
  60% {
    -ms-transform: scale(1.1, 1.1);
    -webkit-transform: scale(1.1, 1.1);
    transform: scale(1.1, 1.1);
  }
  100% {
    -ms-transform: scale(1, 1);
    -webkit-transform: scale(1, 1);
    transform: scale(1, 1);
  }
}
@-o-keyframes fall-in {
  0% {
    -ms-transform: scale(3, 3);
    -webkit-transform: scale(3, 3);
    transform: scale(3, 3);
    opacity: 0;
  }
  50% {
    -ms-transform: scale(1, 1);
    -webkit-transform: scale(1, 1);
    transform: scale(1, 1);
    opacity: 1;
  }
  60% {
    -ms-transform: scale(1.1, 1.1);
    -webkit-transform: scale(1.1, 1.1);
    transform: scale(1.1, 1.1);
  }
  100% {
    -ms-transform: scale(1, 1);
    -webkit-transform: scale(1, 1);
    transform: scale(1, 1);
  }
}
@keyframes fall-in {
  0% {
    -ms-transform: scale(3, 3);
    -webkit-transform: scale(3, 3);
    transform: scale(3, 3);
    opacity: 0;
  }
  50% {
    -ms-transform: scale(1, 1);
    -webkit-transform: scale(1, 1);
    transform: scale(1, 1);
    opacity: 1;
  }
  60% {
    -ms-transform: scale(1.1, 1.1);
    -webkit-transform: scale(1.1, 1.1);
    transform: scale(1.1, 1.1);
  }
  100% {
    -ms-transform: scale(1, 1);
    -webkit-transform: scale(1, 1);
    transform: scale(1, 1);
  }
}
@-moz-keyframes plunge {
  0% {
    margin-top: -100%;
  }
  100% {
    margin-top: 15%;
  }
}
@-webkit-keyframes plunge {
  0% {
    margin-top: -100%;
  }
  100% {
    margin-top: 15%;
  }
}
@-o-keyframes plunge {
  0% {
    margin-top: -100%;
  }
  100% {
    margin-top: 15%;
  }
}
@keyframes plunge {
  0% {
    margin-top: -100%;
  }
  100% {
    margin-top: 15%;
  }
}

.gradient-custom-2 {
  /* fallback for old browsers */
  background: #000000;

  /* Chrome 10-25, Safari 5.1-6 */
  background: -webkit-linear-gradient(to right, #ffffff17, #2c3039ad, #00000000);

  /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  background: linear-gradient(to right, #ffffff17, #2c3039ad, #00000000);
}

@media (min-width: 769px) {
  .gradient-custom-2 {
    border-top-right-radius: 0.3rem;
    border-bottom-right-radius: 0.3rem;
  }
}

@media only screen and (max-width: 280px) {
  .gradient-form {
    background: transparent;
    margin-top: 2rem;
    min-height: 100vh;
  }
  .modal-content {
    height: 26rem;
    width: 100%;
  }
  .infohead {
    font-size: 1.2rem;
    font-weight: 600;
  }
  .infopara {
    font-size: small;
  }
  #iyer2,
  #iyer4,
  #iyer6,
  #iyer8,
  #iyer10 {
    height: 7rem;
    width: 7rem;
    margin-bottom: 2rem;
  }
  #iyer,
  #iyer3,
  #iyer5,
  #iyer7,
  #iyer9 {
    font-size: 4rem;
    color: #ffffff;
  }
}

@media only screen and (min-width: 281px) and (max-width: 360px) {
  .gradient-form {
    background: transparent;
    margin-top: 2rem;
    min-height: 100vh;
  }
  .modal-content {
    height: 26rem;
    width: 100%;
  }
  .infohead {
    font-size: 1.2rem;
    font-weight: 600;
  }
  .infopara {
    font-size: 0.9rem;
  }
  #iyer2,
  #iyer4,
  #iyer6,
  #iyer8,
  #iyer10 {
    height: 7rem;
    width: 7rem;
    margin-bottom: 2rem;
  }
  #iyer,
  #iyer3,
  #iyer5,
  #iyer7,
  #iyer9 {
    font-size: 4rem;
    color: #ffffff;
  }
}

@media only screen and (min-width: 361px) and (max-width: 600px) {
  .gradient-form {
    background: transparent;
    margin-top: 2rem;
    min-height: 100vh;
  }
  .modal-content {
    height: 26rem;
    width: 95%;
  }
  .infohead {
    font-size: 1.2rem;
    font-weight: 600;
  }
  .infopara {
    font-size: 1rem;
  }
  #iyer2,
  #iyer4,
  #iyer6,
  #iyer8,
  #iyer10 {
    height: 7rem;
    width: 7rem;
    margin-bottom: 2rem;
  }
  #iyer,
  #iyer3,
  #iyer5,
  #iyer7,
  #iyer9 {
    font-size: 4rem;
    color: #ffffff;
  }
}

@media only screen and (min-width: 601px) and (max-width: 1024px) {
  .gradient-form {
    background: transparent;
    margin-top: 0rem;
    min-height: 100vh;
  }
  .modal-content {
    height: 26rem;
    width: 100%;
  }
  #iyer2,
  #iyer4,
  #iyer6,
  #iyer8,
  #iyer10 {
    height: 10rem;
    width: 10rem;
    margin-bottom: 2rem;
  }
  #iyer,
  #iyer3,
  #iyer5,
  #iyer7,
  #iyer9 {
    font-size: 5rem;
    color: #ffffff;
  }
  #rower {
    margin-top: 1.2rem;
  }
  #rower2 {
    margin-top: 1.2rem;
  }
}

@media only screen and (min-width: 1025px) {
  .gradient-form {
    background: transparent;
    margin-top: 0rem;
    min-height: 100vh;
  }
  .modal-content {
    height: 26rem;
    width: 100%;
  }
  #iyer2,
  #iyer4,
  #iyer6,
  #iyer8,
  #iyer10 {
    height: 10rem;
    width: 10rem;
    margin-bottom: 2rem;
  }
  #iyer,
  #iyer3,
  #iyer5,
  #iyer7,
  #iyer9 {
    color: #ffffff;
    font-size: 5rem;
  }
  #rower {
    margin-top: 2rem;
  }
  #rower2 {
    margin-top: 2rem;
  }
}
/* Style for the cropping modal container */
.cropper-container {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  min-width: 40%; /* Adjust as needed */
  height: 55%; /* 50% of viewport height */
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0); /* Optional: dimmed background */
  background: none;
  z-index: 1000; /* Ensure it appears on top */
}

/* Style for the cropper area */
.cropper {
  position: relative;
  width: 100%;
  height: 100%;
}

/* Ensure Cropper takes full space of its container */
.reactEasyCrop_Container {
  width: 100%;
  height: 100%;
}

/* Style for the Crop Image button */
.crop-button {
  position: absolute;
  bottom: 20px; /* Adjust as needed */
  right: 20px; /* Adjust as needed */
  padding: 10px 20px;
  background-color: #007bff; /* Button background color */
  color: white; /* Button text color */
  border: none;
  border-radius: 5px;
  cursor: pointer;
  z-index: 10; /* Ensure button is on top */
}

#exampleModal3.show {
  overflow: hidden;
}
#exampleModal2.show {
  overflow: hidden;
}
#exampleModal.show {
  overflow: hidden;
}

#exampleModal .modal-content {
  background: #000000d9;
}
#exampleModal .modal-content .modal-header,
#exampleModal .modal-content .modal-header .modalbox {
  background: transparent;
  color: white !important;
  border: none;
}

#exampleModal2 .modal-content {
  background: #000000d9;
}
#exampleModal2 .modal-content .modal-header,
#exampleModal2 .modal-content .modal-header .modalbox {
  background: transparent;
  color: white !important;
  border: none;
}

#exampleModal3 .modal-content {
  background: #000000d9;
}
#exampleModal3 .modal-content .modal-header,
#exampleModal3 .modal-content .modal-header .modalbox {
  background: transparent;
  color: white !important;
  border: none;
}

#exampleModal .modal-fade.show {
  background: #000000d9;
  display: flex !important;
}
#exampleModal1 .modal-fade.show {
  background: #000000d9;
  display: flex !important;
}
#exampleModal2 .modal-fade.show {
  background: #000000d9;
  display: flex !important;
}
