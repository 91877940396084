.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

#razorpay-payment-form2 .PaymentButton-contents.svelte-ekc7fv.svelte-ekc7fv {
  padding: 4px 3px 4px 44px;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* Hide navbar on desktop screens */
@media (min-width: 1600px) {
  .navbar {
    display: none !important;
  }
  #wrapper {
    margin: 0rem !important;
    width: 80% !important;
  }
  .wrapper {
    justify-content: space-between !important;
    background-color: #000000 !important;
    opacity: 1;
    background-image: url("./icons/backgroundAppDesk.jpg");
    background-size: cover;
  }
  .fileFolderaccess2,
  .fileFolderaccess {
    top: 0 !important;
    height: 100% !important;
    border: none !important;
    border-radius: 0 !important;
  }
  .fileFolderaccess {
    left: auto !important;
    width: 83%;
    padding: 2rem 0rem 0rem 2rem !important;
  }
  .fileFolderaccess .content {
    border-top: 2px solid white;
    border-left: 2px solid white;
    border-radius: 10rem 0rem 0rem 10rem;
    padding: 4rem 0rem 0rem 2rem !important;
    background-color: rgba(157, 127, 255, 0.13);
  }
  .emptyVault {
    height: 83% !important;
    justify-content: center !important;
  }
  .ChatUserList {
    height: 90% !important;
    top: 0rem !important;
  }
  .Nochathello {
    height: 90% !important;
    justify-content: center !important;
  }
  .chatWrapper {
    align-items: center !important;
    height: 100% !important;
    margin: 0rem 1rem 0rem 0rem !important;
  }
  .ChatArea {
    height: 95% !important;
    width: 72% !important;
  }
  .individual-teamX256 {
    height: 100% !important;
    justify-content: space-between !important;
    margin: 0rem 1rem 0rem 0rem !important;
  }
  .chat-areaX256 {
    margin-bottom: 2rem !important;
    height: 88% !important;
  }
  .indiTeamHeaderX256 {
    margin: 0rem 0rem 1rem 0rem !important;
  }
  .questions-areaX256 {
    height: 90% !important;
    margin: 0rem 0rem 1rem 0rem !important;
  }
  .search-results1 {
    width: 50rem !important;
    top: 9.77rem !important;
  }
  .searchbar2 {
    width: 50rem !important;
  }
  .dashboarderRow .card-body {
    padding: 0.5rem !important ;
  }
  .clearfix {
    margin: 0 !important;
    justify-content: space-between !important;
  }
  .float-right {
    height: 100% !important;
  }
  .float-right figure {
    height: 100% !important;
    display: flex !important;
    flex-direction: column !important;
    justify-content: space-between !important;
  }
  .float-right figure .blockquote {
    height: 100% !important;
    display: flex !important;
    flex-direction: column !important;
    justify-content: center !important;
  }
  .charAreafoot {
    margin-top: 1rem !important;
  }
}
.razorpay-payment-form .PaymentForm {
  width: 40% !important;
}
.full-screen-chat {
  width: 100% !important;
  height: 100% !important;
  margin: 0 !important;
  padding: 0 !important;
}
.full-screen-top {
  top: 0 !important;
}
.margner {
  margin-top: 0.4rem !important;
}
.chatExpander {
  color: aqua;
  font-size: 1.5rem;
  transition: all 0.1s ease-in-out;
}
.chatExpander:hover {
  color: white;
  font-size: 2rem;
}
.chatCollapser {
  color: aqua;
  font-size: 2rem;
  transition: all 0.1s ease-in-out;
}
.chatCollapser:hover {
  color: white;
  font-size: 1.5rem;
}
/* Show navbar on mobile and tablet screens */
@media (max-width: 1599px) {
  .teamElem {
    margin: 0rem 1rem 0rem 1rem !important;
  }
  .wrapper {
    flex-direction: column !important;
    background-color: #000000 !important;
    opacity: 1;
    background-image: url("./icons/backgroundAppDesk.jpg");
    background-size: cover;
  }
  .chatWrapper {
    height: 100% !important;
  }
  .ChatArea {
    height: 100% !important;
    width: 100% !important;
    margin: 0rem 0.5rem !important;
  }
  .navbar {
    display: flex;
  }
  #wrapper {
    width: 100% !important;
    margin: 0rem !important;
  }
  .SideBar {
    display: none;
  }
  .fileFolderaccess {
    top: 5rem;
    left: 0 !important;
    width: 100%;
    border-radius: 0rem !important;
    border: none !important;
    padding: 0rem 0rem 0rem 0rem !important;
  }
  .fileFolderaccess .content {
    border: none !important;
    border-radius: 0rem !important;
    height: 100% !important;
    background-color: rgba(157, 127, 255, 0.13);
    padding: 2rem 2rem 1rem 2rem !important;
  }
  .show-on-mobile {
    display: none !important;
  }
  .dashboarderRow .card-body {
    padding: 0.5rem !important ;
  }
  .clearfix {
    margin: 0 !important;
    justify-content: space-between !important;
  }
  .float-right {
    height: 100% !important;
  }
  .float-right figure {
    height: 100% !important;
    display: flex !important;
    flex-direction: column !important;
    justify-content: space-between !important;
  }
  .float-right figure .blockquote {
    height: 100% !important;
    display: flex !important;
    flex-direction: column !important;
    justify-content: center !important;
  }
}
@media only screen and (max-width: 600px) {
  .wrapper {
    background-color: #000000 !important;
    opacity: 1;
    background-image: url("./icons/backgroundAppMob.jpg");
    background-size: cover;
  }
  .container .selectionRef .row .col .card {
    height: 8rem !important;
  }
  .container .selectionRef .row .col .card .folderTypeImg {
    height: 5rem !important;
  }
  .container .selectionRef .row .col .card .card-body .dropdown .card-title {
    font-size: small;
    padding: 0.5rem 0.3rem 0.5rem 0.3rem !important;
    overflow-wrap: anywhere;
    width: 100%;
  }
  .container
    .selectionRef
    .row
    .col
    .card
    .card-body
    .dropdown
    .dropdownMenuButton2
    i {
    font-size: medium !important;
  }
  .container
    .selectionRef
    .row
    .col
    .card
    .card-body
    .dropdown
    .dropdownMenuButton2 {
    display: flex;
    display: none;
    justify-content: flex-end;
    align-items: center;
    padding: 0.5rem 0.2rem 0.5rem 0.2rem !important;
  }
  .fileFolderaccess .content {
    border: none !important;
    border-radius: 0rem !important;
    height: 100% !important;
    background-color: rgba(157, 127, 255, 0.13);
    padding: 2rem 1rem 1rem 1rem !important;
  }
  .fileSection {
    padding: 2rem 0rem !important;
  }
  .breadcrumb-item {
    font-size: small;
  }
  .rower65 {
    flex-direction: column;
  }
  .filefoldername {
    font-size: small !important;
  }
  .filefoldername img {
    width: 1.4rem !important;
  }
  .pdf-viewer-header .initialer .file-name {
    font-size: medium;
    padding: 0rem 0.5rem !important;
  }
  .pdf-viewer-header .initialer {
    padding: 0rem 0.5rem !important;
  }
  .pdf-viewer-options {
    display: none !important;
  }
  .pdf-mobile-options {
    display: block !important;
  }
  .pdf-mobile-options .dropdown-menu {
    background-color: #040014d5;
    width: 12rem !important;
  }
  .pdf-mobile-options .dropdown-menu li {
    background-color: transparent;
  }
  .image-viewer-header .initialer .file-name {
    font-size: medium;
    padding: 0rem 0.5rem !important;
    margin-left: 0 !important;
  }
  .image-viewer-header .initialer {
    padding: 0rem 0.5rem !important;
  }
  .image-viewer-options {
    display: none !important;
  }
  .image-mobile-options {
    display: block !important;
  }
  .image-mobile-options .dropdown-menu {
    background-color: #040014d5;
    width: 12rem !important;
  }
  .image-mobile-options .dropdown-menu li {
    background-color: transparent;
  }
  .fileFolderaccess .main-panel {
    padding: 0 !important;
  }
  .fileFolderaccess .main-panel .card-body {
    height: 9rem !important;
    padding: 10px !important;
  }
  .dashboarder .titleer h1 {
    font-size: large;
  }
  .dashboarder .profilepic {
    width: 35% !important;
    margin: 0 !important;
  }
  .dashboarder .profilepic .editpfpic {
    width: 0.8rem !important;
  }
  .dashboarder .profilepic button .profilepIc {
    width: 6rem !important;
  }
  .dashboarder .profilepic button {
    width: 6rem !important;
    height: 7rem !important;
  }
  .dashboarder .img {
    width: 30% !important;
  }
  .dashboarder .img img {
    width: 4rem !important;
  }
  .dashboarder .clearfix {
    width: 70% !important;
    margin: 0 !important;
  }
  .dashboarder .clearfix .float-right h1 {
    font-size: 2rem !important;
  }
  .dashboarder .clearfix .float-right .blockquote-footer {
    font-size: 0.7em !important;
  }
  .headrerCard .entries-selector {
    flex-direction: column !important;
  }
  .entries-selector label {
    text-align: center;
  }
  .card .card-body.raga {
    height: auto !important;
    min-height: 19rem !important;
  }
  .raga #datatable thead,
  tbody {
    font-size: small !important;
  }
  .raga #datatable thead tr th {
    font-size: small !important;
    padding: 10px !important;
  }
  .raga #datatable tbody tr td {
    font-size: small !important;
    padding: 10px !important;
  }
  .pagination {
    width: 100%;
    justify-content: center !important;
    align-items: center !important;
  }
  .pagination .pager {
    display: none !important;
  }
  .ChatUserList {
    width: 100% !important;
    display: flex !important;
    flex-direction: column !important;
    justify-content: center !important;
    align-items: center !important;
  }
  .ChatUserList .userList {
    width: 94% !important;
    display: flex !important;
    flex-direction: column !important;
  }
  .mobileSet {
    margin-top: 1rem !important;
    width: 94% !important;
  }
  .hide-on-mobile {
    display: none !important;
  }
  .Nochathello {
    display: none !important;
  }
  .chatWrapper {
    height: 100% !important;
  }
  .ChatArea {
    height: 100% !important;
    width: 100% !important;
    margin: 0rem 0.5rem !important;
  }
  .navbar {
    height: 8vh !important;
  }
  .navbar img {
    width: 9rem !important;
  }
  .gifMessage {
    width: 10rem !important;
    height: auto !important;
  }
  .gifGrpmessage {
    width: 75vw !important;
    height: auto !important;
    min-height: auto !important;
    max-height: none !important;
  }
  .chatAreahead h1 {
    font-size: x-large !important;
    margin: 0rem !important;
  }
  .chatAreahead {
    padding: 0.5rem !important;
    flex-direction: column !important;
    justify-content: center !important;
    align-items: flex-start !important;
  }
  .chatAreahead .toolbarmsg {
    flex-direction: column !important;
    position: fixed !important;
    top: 11rem;
    left: 0.6rem;
    padding: 0rem 0.2rem !important;
  }
  .show-on-mobile {
    display: block !important;
    margin-right: 0.5rem;
    background-color: transparent !important;
    box-shadow: none !important;
    border: none !important;
  }
  .mobilechathead {
    display: flex !important;
  }
  .charAreafoot {
    height: 8% !important;
    align-items: center !important;
  }
  .charAreafoot textarea {
    padding: 0.5rem 1rem !important;
    height: 90%;
  }
  .ChatArea .messagesContainer {
    height: 80% !important;
  }
  .charAreafoot button {
    padding: 0.1rem !important;
  }
  .charAreafoot .sendmsg {
    width: 18% !important;
  }
  .charAreafoot .sendmsg img {
    width: 3rem !important;
  }
  .teamElem {
    margin: 0rem 0.8rem 0rem 0.8rem !important;
  }
  .teamElem h1 {
    margin-top: 1rem !important;
  }
  .manageTeambut {
    width: 45% !important;
    align-items: flex-end !important;
    display: flex !important;
    flex-direction: column !important;
  }
  .manageTeambut button {
    margin: 0.25rem 0rem !important;
    align-items: center;
  }
  .mobileModal {
    margin: 0 !important;
  }
  .mobileModal .modal-header {
    padding-right: 1rem !important;
  }
  .mobileModal #heading h5 {
    font-size: 1.5rem !important;
  }
  .mobileModal .modal-header h5 {
    font-size: 1.8rem !important;
  }
  .modCon {
    width: 90% !important;
    height: 80% !important;
  }
  #teamManagementModal .modCon {
    height: 55% !important;
  }
  #teamManagementModal .modal-body {
    padding: 0.8rem !important;
  }
  .adder h4,
  .remover h4,
  .managerPer h4 {
    font-size: large !important;
  }
  .adder .teamModalButton,
  .remover .teamModalButton,
  .managerPer .teamModalButton {
    margin-left: 0.5rem !important;
    width: 15% !important;
    height: 2rem !important;
  }
  .managerPer h3 {
    font-size: 0.9rem !important;
  }
  #managerPername {
    font-size: 1.3rem !important;
  }
  .checkboxUltra:checked + .switch .slider {
    transform: translateX(calc(3.6rem - 27px)) translateY(-50%) !important;
  }
  .managerPer span {
    font-size: large !important;
  }
  #managePermissionsModal .modal-body {
    padding: 1rem 0rem 1rem 0.5rem !important;
  }
  .managerPer .managerPerdiv {
    padding: 0.5rem 0rem 0.5rem 0.5rem !important;
  }
  .myTeamer .myTeamer2 {
    padding: 0.5rem 0rem 0.5rem 0.5rem !important;
    height: 5.5rem !important;
  }

  .myTeamer .myTeamer2 h3 {
    width: 55%;
    overflow-wrap: anywhere;
  }

  .chat-areaX256 {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100% !important;
  }
  .chat-areaX256 .charAreafoot {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 10% !important;
    margin-top: 0.5rem !important;
    padding: 0rem 0.5rem !important;
  }
  .chat-areaX256 .charAreafoot textarea {
    height: 3rem !important;
  }
  .individual-teamX256 {
    height: 100% !important;
  }
  .chat-areaX256 h4 {
    font-size: large !important;
  }
  .chat-areaX256 .messagesX256 {
    height: 87% !important;
    padding: 1rem 0.5rem !important;
    overflow-x: hidden;
  }
  .indiTeamHeaderX256 {
    margin: 0.5rem !important;
  }
  .indiTeamHeaderX256 .together h2 {
    font-size: larger !important;
  }
  .together button i {
    font-size: 1.7rem !important;
  }
  .together button {
    margin-right: 0.4rem !important;
  }
  .backerHead button i {
    font-size: 1.2rem !important;
  }
  .indiTeamHeaderX256 .sharedselector {
    width: 40% !important;
  }
  .indiTeamHeaderX256 .sharedselector .shselectbutton {
    font-size: small !important;
  }
  .quesHeader h4 {
    font-size: larger !important;
  }
  .question-itemX256 {
    padding: 0.2rem 0.6rem !important;
    border-top: 0.2px solid aqua;
  }
  .question-itemX256 h5 {
    font-size: larger !important;
  }
  .question-itemX256 p {
    font-size: medium !important;
  }
  .headerQues small {
    margin: 0.6rem 0rem;
    font-size: 0.7rem !important;
  }
  #addQuesModal .modal-dialog .modal-content .modal-body .input-group {
    margin-top: 3rem !important;
  }
  #addQuesModal .heading h5 {
    font-size: 1.5rem !important;
  }

  #addQuesModal .formbut {
    padding-bottom: 1rem !important;
  }
  .custom-tooltip .tooltip-inner {
    width: auto !important;
    max-width: auto !important;
  }
  #addQuesModal .modal-dialog {
    margin: 0 !important;
  }
  .quesdethead strong {
    font-size: 0.7rem !important;
  }
  .backerHead h4 {
    font-size: 1.2rem !important;
  }
  .question-detailsX256 {
    padding: 0.2rem !important;
  }
  .questiontriang {
    width: 94% !important;
    padding: 0rem 0.3rem !important;
  }
  .questiontriang .vote-buttons {
    margin: 0rem 1rem 0rem 0.1rem !important ;
  }
  .replytriang {
    width: 97% !important;
    padding: 0rem !important;
  }
  .replytriang .vote-buttons {
    margin: 0rem 1rem 0rem 0.1rem !important ;
  }
  .replytriang .vote-buttons .vote-btn {
    height: 2.5rem !important;
    width: 2.5rem !important;
  }
  .questions-areaX256 {
    height: 100% !important;
  }
  .reply-textX256 {
    width: 85% !important;
  }
  .buttner {
    margin: 1rem 0rem !important;
  }
  #addRepModal .modal-dialog {
    margin: 0 !important;
  }

  #addRepModal .modal-dialog .modal-content {
    width: 90% !important;
  }
  #addRepModal .modal-dialog .modal-content .modal-body .input-group {
    margin-top: 3rem !important;
  }
  .emptyVault img {
    width: 80% !important;
  }
  .emptyVault h4 {
    text-align: center !important;
  }
  .emptyVault {
    position: fixed !important;
    top: 30% !important;
  }
  .cropper-container {
    min-width: 90% !important;
  }
  .Userprofilepic {
    margin-left: 0 !important;
  }
  .userprofilepIcemp {
    width: 3rem !important;
  }
  .userprofilepIcnonemp {
    width: 3rem !important;
    border-radius: 5rem !important;
  }
  .togetherdpname2 img {
    width: 2rem !important;
  }
  .tdButDash {
    font-size: 0.8rem !important;
  }
  #datatable {
    width: max-content !important;
  }
  .togetherdpname2 {
    justify-content: space-evenly !important;
  }
  .togetherdpname2 span {
    width: 50% !important;
  }
  .roleIconGrp {
    width: 1.5rem !important;
  }
  .userprofilepIc {
    width: 3rem !important;
  }
  .form-check-labeler {
    font-size: medium !important;
  }
  .nklwe {
    flex-direction: column !important;
  }
  #shareItemModalLabel {
    font-size: 1.5rem !important;
  }
  .zerodha.aqua-button {
    margin: 0rem !important;
  }
  .shareItemModf .banner {
    font-size: large !important;
  }
  .shareItemModf .banner-message {
    padding: 0 !important;
  }
  .shareItemModf .banner-close i {
    font-size: 1.5rem;
  }
  .inMobNew #dropdownMenuButton1 {
    width: 6rem !important;
    height: 3rem !important;
    border-radius: 1rem !important;
    font-size: large !important;
  }
  #uploadConf .modal-dialog .modal-content {
    height: fit-content !important;
    width: 90% !important;
  }
  #uploader1 .dropdown-item label img {
    width: 1rem !important;
    height: auto !important;
  }
  .fileFolderaccess,
  .fileFolderaccess2 {
    height: -webkit-fill-available !important;
  }
}

@media only screen and (min-width: 530px) and (max-width: 599px) {
  .checkboxUltra:checked + .switch .slider {
    transform: translateX(calc(4.6rem - 27px)) translateY(-50%) !important;
  }
  .userprofilepIc {
    max-width: 3rem !important;
  }
  .rower65 {
    flex-direction: column;
  }
  .inMobNew #dropdownMenuButton1 {
    width: 6rem !important;
    height: 3rem !important;
    border-radius: 1rem !important;
    font-size: large !important;
  }
  #uploadConf .modal-dialog .modal-content {
    height: fit-content !important;
    width: 70% !important;
  }
}

@media only screen and (min-width: 601px) and (max-width: 759px) {
  .teamElem {
    margin: 0rem 1rem 0rem 1rem !important;
  }
  .titleer {
    width: 70% !important;
  }
  .profilepic {
    position: absolute !important;
    right: 0 !important;
    height: 100% !important;
    width: auto !important;
    margin-left: 0rem !important;
    margin-right: 1rem !important;
  }
  .profilepIc {
    width: 10rem !important;
  }
  .profilepic button {
    width: 10rem !important;
    height: 10rem !important;
  }
  .image-viewer-options {
    display: none !important;
  }
  .image-mobile-options {
    display: block !important;
  }
  .image-mobile-options .dropdown-menu {
    background-color: #040014d5;
    width: 12rem !important;
  }
  .image-mobile-options .dropdown-menu li {
    background-color: transparent;
  }
  .pdf-viewer-options {
    display: none !important;
  }
  .pdf-mobile-options {
    display: block !important;
  }
  .pdf-mobile-options .dropdown-menu {
    background-color: #040014d5;
    width: 12rem !important;
  }
  .pdf-mobile-options .dropdown-menu li {
    background-color: transparent;
  }
  .hide-on-mobile {
    display: none !important;
  }
  .Nochathello {
    display: none !important;
  }
  .chatWrapper {
    height: 100% !important;
  }
  .ChatArea {
    height: 100% !important;
    width: 100% !important;
    margin: 0rem 0.5rem !important;
  }
  .chatAreahead h1 {
    font-size: x-large !important;
    margin: 0rem !important;
  }

  .chatAreahead .toolbarmsg {
    flex-direction: column !important;
    position: fixed !important;
    top: 11rem;
    left: 0.6rem;
    padding: 0rem 0.2rem !important;
  }
  .show-on-mobile {
    display: block !important;
    margin-right: 0.5rem;
    background-color: transparent !important;
    box-shadow: none !important;
    border: none !important;
  }
  .mobilechathead {
    display: flex !important;
  }
  .chatAreahead {
    height: 8%;
    padding: 0.5rem !important;
    flex-direction: column !important;
    justify-content: center !important;
    align-items: flex-start !important;
  }
  .charAreafoot {
    height: 7% !important;
    align-items: center !important;
  }
  .charAreafoot textarea {
    padding: 0.5rem 1rem !important;
    height: 3rem !important;
  }
  .ChatArea .messagesContainer {
    height: 81% !important;
  }
  .charAreafoot button {
    padding: 0.1rem !important;
  }
  .charAreafoot .sendmsg {
    width: 18% !important;
  }
  .charAreafoot .sendmsg img {
    width: 3rem !important;
  }

  .gifMessage {
    width: 17rem !important;
    height: auto !important;
  }
  .rower65 {
    flex-direction: column;
  }
  .ChatUserList {
    width: 100% !important;
    flex-direction: column !important;
    justify-content: center !important;
    align-items: center !important;
  }
  .ChatUserList .userList {
    width: 94% !important;
    display: flex !important;
    justify-content: center !important;
    flex-direction: column !important;
  }
  .mobileSet {
    margin-top: 1rem !important;
    width: 94% !important;
  }
  .emptyVault img {
    width: 80% !important;
  }
  .emptyVault h4 {
    text-align: center !important;
  }
  .emptyVault {
    position: fixed !important;
    top: 25% !important;
  }
  #folderSecDiv .container {
    margin: 0 !important;
    width: 100% !important;
    max-width: 100% !important;
  }
  .fileFolderaccess .content {
    padding: 2rem 0.5rem !important;
  }
  .userprofilepIc {
    max-width: 3rem !important;
  }
  .mobileModal {
    margin: 0 !important;
  }
  .messagesContainer {
    height: 80% !important;
  }
  .mobileModal .modal-header {
    padding-right: 1rem !important;
  }
  .mobileModal #heading h5 {
    font-size: 1.8rem !important;
  }
  .mobileModal .modal-header h5 {
    font-size: 1.8rem !important;
  }
  .modCon {
    width: 90% !important;
    height: 80% !important;
  }
  #teamManagementModal .modCon {
    height: 55% !important;
  }
  #teamManagementModal .modal-body {
    padding: 0.8rem !important;
  }
  .adder h4,
  .remover h4,
  .managerPer h4 {
    font-size: x-large !important;
  }
  .adder .teamModalButton,
  .remover .teamModalButton,
  .managerPer .teamModalButton {
    margin-left: 0.5rem !important;
    width: 15% !important;
    height: 2rem !important;
  }
  .managerPer h3 {
    font-size: 0.9rem !important;
  }
  #managerPername {
    font-size: 1.3rem !important;
  }
  .checkboxUltra:checked + .switch .slider {
    transform: translateX(calc(4.6rem - 27px)) translateY(-50%) !important;
  }
  .managerPer span {
    font-size: large !important;
  }
  #managePermissionsModal .modal-body {
    padding: 1rem 0rem 1rem 0.5rem !important;
  }
  .managerPer .managerPerdiv {
    padding: 0.5rem 0rem 0.5rem 0.5rem !important;
  }
  .chat-areaX256 {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100% !important;
  }
  .chat-areaX256 .charAreafoot {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 10% !important;
    margin-top: 0.5rem !important;
    padding: 0rem 0.5rem !important;
  }
  .chat-areaX256 .charAreafoot textarea {
    height: 3rem !important;
  }
  .individual-teamX256 {
    height: 100% !important;
  }
  .chat-areaX256 h4 {
    font-size: large !important;
  }
  .chat-areaX256 .messagesX256 {
    height: 87% !important;
  }

  .indiTeamHeaderX256 {
    margin: 0.5rem !important;
  }
  .indiTeamHeaderX256 .together h2 {
    font-size: x-large !important;
  }
  .together button i {
    font-size: 1.7rem !important;
  }
  .together button {
    margin-right: 0.4rem !important;
  }
  .backerHead button i {
    font-size: 1.2rem !important;
  }
  .indiTeamHeaderX256 .sharedselector {
    width: 40% !important;
  }
  .indiTeamHeaderX256 .sharedselector .shselectbutton {
    font-size: medium !important;
  }
  .quesHeader h4 {
    font-size: x-large !important;
  }
  .question-itemX256 {
    padding: 0.2rem 0.6rem !important;
    border-top: 0.2px solid aqua;
  }
  .question-itemX256 h5 {
    font-size: x-large !important;
  }
  .question-itemX256 p {
    font-size: large !important;
  }
  .questions-areaX256 {
    padding: 0.5rem 0.5rem !important ;
  }
  .headerQues small {
    margin: 0.6rem 0rem;
    font-size: 0.9rem !important;
  }
  #addQuesModal .modal-dialog .modal-content .modal-body .input-group {
    margin-top: 3rem !important;
  }

  #addQuesModal .formbut {
    padding-bottom: 1rem !important;
  }
  .custom-tooltip .tooltip-inner {
    width: auto !important;
    max-width: auto !important;
  }
  #addQuesModal .modal-dialog {
    margin: 0 !important;
  }
  .quesdethead strong {
    font-size: 0.9rem !important;
  }
  .backerHead h4 {
    font-size: 1.5rem !important;
  }
  .question-detailsX256 {
    padding: 0.2rem !important;
  }
  .questiontriang {
    width: 94% !important;
    padding: 0rem 0.3rem !important;
  }
  .questiontriang .vote-buttons {
    margin: 0rem 1rem 0rem 0.1rem !important ;
  }
  .replytriang {
    width: 97% !important;
    padding: 0rem !important;
  }
  .replytriang .vote-buttons {
    margin: 0rem 1rem 0rem 0.1rem !important ;
  }
  .replytriang .vote-buttons .vote-btn {
    height: 2.5rem !important;
    width: 2.5rem !important;
  }
  .questions-areaX256 {
    height: 100% !important;
  }
  .reply-textX256 {
    width: 85% !important;
  }
  .buttner {
    margin: 1rem 0rem !important;
  }
  #addRepModal .modal-dialog {
    margin: 0 !important;
  }

  #addRepModal .modal-dialog .modal-content {
    width: 90% !important;
  }
  #addRepModal .modal-dialog .modal-content .modal-body .input-group {
    margin-top: 3rem !important;
  }
  .inMobNew #dropdownMenuButton1 {
    width: 6rem !important;
    height: 3rem !important;
    border-radius: 1rem !important;
    font-size: large !important;
  }
  #uploadConf .modal-dialog .modal-content {
    height: fit-content !important;
    width: 50% !important;
  }
}

@media only screen and (min-width: 760px) and (max-width: 1199px) {
  .teamElem {
    margin: 0rem 1rem 0rem 1rem !important;
  }
  .titleer {
    width: 70% !important;
  }
  .profilepic {
    position: absolute !important;
    right: 0 !important;
    height: 100% !important;
    width: auto !important;
    margin-left: 0rem !important;
    margin-right: 1rem !important;
  }
  .profilepIc {
    width: 10rem !important;
  }
  .profilepic button {
    width: 10rem !important;
    height: 10rem !important;
  }
  .userprofilepIc {
    max-width: 3rem !important;
  }
  .dashboarder {
    height: 100% !important;
  }
  .dashboarder .img img {
    width: 3rem !important;
  }
  .float-right .display-6 {
    font-size: 1.5rem !important;
  }
  .blockquote-footer {
    font-size: x-small;
  }
  .dashboarderRow .card-body {
    padding: 0.5rem !important ;
  }
  .clearfix {
    margin: 0 !important;
    justify-content: space-between !important;
  }
  .float-right {
    height: 100% !important;
  }
  .float-right figure {
    height: 100% !important;
    display: flex !important;
    flex-direction: column !important;
    justify-content: space-between !important;
  }
  .float-right figure .blockquote {
    height: 100% !important;
    display: flex !important;
    flex-direction: column !important;
    justify-content: center !important;
  }
  .hide-on-mobile {
    display: none !important;
  }
  .Nochathello {
    display: none !important;
  }
  .chatWrapper {
    height: 100% !important;
  }
  .ChatArea {
    height: 100% !important;
    width: 100% !important;
    margin: 0rem 0.5rem !important;
  }
  .chatAreahead h1 {
    font-size: xx-large !important;
    margin: 0rem !important;
  }

  .show-on-mobile {
    display: block !important;
    margin-right: 0.5rem;
    background-color: transparent !important;
    box-shadow: none !important;
    border: none !important;
  }
  .mobilechathead {
    display: flex !important;
  }
  .charAreafoot {
    height: 7% !important;
    align-items: center !important;
  }
  .charAreafoot textarea {
    padding: 0.5rem 1rem !important;
    height: 100% !important;
  }
  .chatAreahead {
    height: 8% !important;
  }
  .ChatArea .messagesContainer {
    height: 84% !important;
  }
  .charAreafoot button {
    padding: 0.1rem !important;
  }
  .charAreafoot .sendmsg {
    width: 18% !important;
  }
  .charAreafoot .sendmsg img {
    width: 3rem !important;
  }

  .gifMessage {
    width: 17rem !important;
    height: auto !important;
  }
  .ChatUserList {
    width: 100% !important;
    flex-direction: column !important;
    justify-content: center !important;
    align-items: center !important;
  }
  .ChatUserList .userList {
    width: 94% !important;
    display: flex !important;
    justify-content: center !important;
    flex-direction: column !important;
  }
  .mobileSet {
    margin-top: 1rem !important;
    width: 94% !important;
  }
  .emptyVault img {
    width: 80% !important;
  }
  .emptyVault h4 {
    text-align: center !important;
  }
  .emptyVault {
    position: fixed !important;
    top: 20% !important;
  }
  #folderSecDiv .container {
    margin: 0 !important;
    width: 100% !important;
    max-width: 100% !important;
  }
  .fileFolderaccess .content {
    padding: 2rem 0.5rem !important;
  }

  .mobileModal {
    margin: 0 !important;
  }
  .mobileModal .modal-header {
    padding-right: 1rem !important;
  }
  .mobileModal #heading h5 {
    font-size: 1.8rem !important;
  }
  .mobileModal .modal-header h5 {
    font-size: 1.8rem !important;
  }
  .modCon {
    width: 90% !important;
    height: 80% !important;
  }
  #teamManagementModal .modCon {
    height: 55% !important;
  }
  #teamManagementModal .modal-body {
    padding: 0.8rem !important;
  }
  .adder h4,
  .remover h4,
  .managerPer h4 {
    font-size: x-large !important;
  }
  .adder .teamModalButton,
  .remover .teamModalButton,
  .managerPer .teamModalButton {
    margin-left: 0.5rem !important;
    width: 15% !important;
    height: 2rem !important;
  }
  .managerPer h3 {
    font-size: 0.9rem !important;
  }
  #managerPername {
    font-size: 1.3rem !important;
  }
  .checkboxUltra:checked + .switch .slider {
    transform: translateX(calc(4.6rem - 27px)) translateY(-50%) !important;
  }
  .managerPer span {
    font-size: large !important;
  }
  #managePermissionsModal .modal-body {
    padding: 1rem 0rem 1rem 0.5rem !important;
  }
  .managerPer .managerPerdiv {
    padding: 0.5rem 0rem 0.5rem 0.5rem !important;
  }
  .chat-areaX256 {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100% !important;
  }
  .chat-areaX256 .charAreafoot {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 10% !important;
    margin-top: 0.5rem !important;
    padding: 0rem 0.5rem !important;
  }
  .chat-areaX256 .charAreafoot textarea {
    height: 3rem !important;
  }
  .charAreafoot textarea {
    height: 3rem !important;
  }
  .individual-teamX256 {
    height: 100% !important;
  }
  .chat-areaX256 h4 {
    font-size: large !important;
  }
  .chat-areaX256 .messagesX256 {
    height: 87% !important;
  }

  .indiTeamHeaderX256 {
    margin: 0.5rem !important;
  }
  .indiTeamHeaderX256 .together h2 {
    font-size: x-large !important;
  }
  .together button i {
    font-size: 1.7rem !important;
  }
  .together button {
    margin-right: 0.4rem !important;
  }
  .backerHead button i {
    font-size: 1.2rem !important;
  }
  .indiTeamHeaderX256 .sharedselector {
    width: 40% !important;
  }
  .indiTeamHeaderX256 .sharedselector .shselectbutton {
    font-size: medium !important;
  }
  .quesHeader h4 {
    font-size: x-large !important;
  }
  .question-itemX256 {
    padding: 0.2rem 0.6rem !important;
    border-top: 0.2px solid aqua;
  }
  .question-itemX256 h5 {
    font-size: x-large !important;
  }
  .question-itemX256 p {
    font-size: large !important;
  }
  .questions-areaX256 {
    padding: 0.5rem 0.5rem !important ;
  }
  .headerQues small {
    margin: 0.6rem 0rem;
    font-size: 0.9rem !important;
  }
  #addQuesModal .modal-dialog .modal-content .modal-body .input-group {
    margin-top: 3rem !important;
  }

  #addQuesModal .formbut {
    padding-bottom: 1rem !important;
  }
  .custom-tooltip .tooltip-inner {
    width: auto !important;
    max-width: auto !important;
  }
  #addQuesModal .modal-dialog {
    margin: 0 !important;
  }
  .quesdethead strong {
    font-size: 0.9rem !important;
  }
  .backerHead h4 {
    font-size: 1.5rem !important;
  }
  .question-detailsX256 {
    padding: 0.2rem !important;
  }
  .questiontriang {
    width: 94% !important;
    padding: 0rem 0.3rem !important;
  }
  .questiontriang .vote-buttons {
    margin: 0rem 1rem 0rem 0.1rem !important ;
  }
  .replytriang {
    width: 97% !important;
    padding: 0rem !important;
  }
  .replytriang .vote-buttons {
    margin: 0rem 1rem 0rem 0.1rem !important ;
  }
  .replytriang .vote-buttons .vote-btn {
    height: 2.5rem !important;
    width: 2.5rem !important;
  }
  .questions-areaX256 {
    height: 100% !important;
  }
  .reply-textX256 {
    width: 85% !important;
  }
  .buttner {
    margin: 1rem 0rem !important;
  }
  #addRepModal .modal-dialog {
    margin: 0 !important;
  }

  #addRepModal .modal-dialog .modal-content {
    width: 90% !important;
  }
  #addRepModal .modal-dialog .modal-content .modal-body .input-group {
    margin-top: 3rem !important;
  }
  .inMobNew #dropdownMenuButton1 {
    width: 6rem !important;
    height: 3rem !important;
    border-radius: 1rem !important;
    font-size: large !important;
  }
  #uploadConf .modal-dialog .modal-content {
    height: fit-content !important;
    width: 50% !important;
  }
}

.pdf-mobile-options {
  display: none;
}
.show-on-mobile {
  display: none;
}
#uploader2 {
  display: flex;
  justify-content: space-around;
  width: 30%;
}
#uploader3 {
  display: flex;
  justify-content: space-around;
  width: 70%;
}
.create-folder-container {
  display: flex;
  justify-content: flex-start;
  margin-bottom: 3rem;
}
#create-folder1 {
  width: 80%;
}
#create-folder2 {
  width: 10%;
}
.folderSection {
  display: flex;
  flex-direction: column;
  justify-content: revert-layer;
  padding: 2rem 3rem;
  list-style-type: none;
}
.folderSectionlistitem {
  padding: 0.5rem 0rem;
  border: 1px solid grey;
  margin-bottom: 1rem;
  background-color: #282c34;
  color: white;
}
.folderSectionlistitem:hover {
  background-color: #ffffff;
  color: rgb(0, 0, 0);
  transition: all 0.1s ease-in;
}
.folderitem {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.folderitemname {
  padding-left: 1rem;
}
.folderbuttons {
  margin: 0rem 1rem;
  width: 6rem;
  border-radius: 0.3rem;
}
.fileSection {
  display: flex;
  flex-direction: column;
  justify-content: revert-layer;
  padding: 2rem 3rem;
  list-style-type: none;
}
.fileSectionlistitem {
  border: 1px solid grey;
  margin-bottom: 1rem;
  background-color: #282c34;
  color: white;
}
.fileSectionlistitem:hover {
  background-color: #626e88;
  color: rgb(255, 255, 255);
  transition: all 0.1s ease-in-out;
}
.fileitem {
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.fileitemname {
  height: 100%;
  width: -webkit-fill-available;
  justify-content: flex-start;
  padding: 0.7rem 0rem 0.7rem 1rem;
}
.filebuttons {
  margin: 0rem 1rem;
  width: 6rem;
  border-radius: 0.3rem;
}
.filefoldername {
  font-size: large;
  font-weight: 500;
}
.selected-files-info {
  position: fixed;
  top: 10rem;
  right: 10px;
  background-color: #f8f9fa;
  border-radius: 5px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transform: translateX(100%); /* Initially off-screen */
  transition: transform 0.4s ease-in-out; /* Smooth transition */
  z-index: 2070;
}

.selected-files-info.visible {
  transform: translateX(0); /* Slide in from the right */

  padding: 10px;
  display: flex;
}

.selected-files-info button {
  margin-left: 10px;
  border: none;
  background-color: #dc3545;
  color: white;
  padding: 5px 10px;
  border-radius: 5px;
  cursor: pointer;
}
.selected-folders-info {
  z-index: 2070;
  position: fixed;
  top: 10rem;
  right: 10px;
  background-color: #f8f9fa;
  border-radius: 5px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transform: translateX(100%); /* Initially off-screen */
  transition: transform 0.4s ease-in-out; /* Smooth transition */
}

.selected-folders-info.visible {
  transform: translateX(0); /* Slide in from the right */
  padding: 10px;
}
.selected-folders-info button {
  margin-left: 10px;
  border: none;
  background-color: #dc3545;
  color: white;
  padding: 5px 10px;
  border-radius: 5px;
  cursor: pointer;
}

.selected {
  background-color: white !important;
  color: black !important;
}
.selected-file-btn {
  color: black !important;
}
.selected-file-eye {
  color: black !important;
}
.selected-folder {
  background-color: white !important;
}
.selected-folder-title {
  color: black !important;
}
.selected-folder-btn {
  color: black !important;
}

.togetherdpname {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: -webkit-fill-available;
}
.togetherdpname2 {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.replyer {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 2rem;
}

.userprofilepIc {
  width: 4rem;
  border-radius: 5rem !important;
}
.userprofilepIcnonemp {
  width: 4rem;
  border-radius: 5rem !important;
}
.Userprofilepic {
  margin-right: 0.5rem;
}

.back-button {
  margin: 1rem 0px;
  padding: 0.5rem 2rem;
  background-color: #000000;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.back-button:hover {
  background-color: #b8b8b8;
  color: #000000;
}

.navbar-brand {
  font-size: x-large;
  font-weight: 400;
}

.fixed-progress-bar {
  position: fixed;
  bottom: 20px; /* Adjust as needed */
  right: 20px; /* Adjust as needed */
  width: 250px; /* Increase the width for better visibility */
  z-index: 5000; /* Ensure it stays on top of other elements */
  padding: 10px; /* Optional: Add some padding around */
  background-color: rgba(
    255,
    255,
    255,
    0.8
  ); /* Optional: Background with transparency */
  border-radius: 8px; /* Optional: Rounded corners */
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1); /* Optional: Add a shadow for better visibility */
}

/* App.css */
.dropdown-container {
  position: relative;
  display: inline-block;
}

.new-button {
  position: fixed;
  top: 5rem;
  left: 1.5rem;
  background-color: #007bff; /* Bootstrap blue */
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 4px;
  cursor: pointer;
}

@keyframes dropdownSlideDown {
  0% {
    height: 0;
    opacity: 0;
  }
  100% {
    height: 12.3rem;
    opacity: 1;
  }
}
@keyframes dropdownpdfmobSlideDown {
  0% {
    height: 0;
    opacity: 0;
    transform: translate3d(0rem, 0rem, 0rem) !important;
  }
  100% {
    height: 18.3rem;
    opacity: 1;
    transform: translate3d(-10rem, 1.5rem, 0px) !important;
  }
}
@keyframes dropdownfileSlideDown {
  0% {
    height: 0;
    opacity: 0;
  }
  100% {
    height: 15.35rem;
    opacity: 1;
  }
}
@keyframes dropdownsenterSlideDown {
  0% {
    height: 0;
    opacity: 0;
  }
  100% {
    height: 100%;
    opacity: 1;
  }
}
@keyframes dropdownShFolSlideDown {
  0% {
    height: 0;
    opacity: 0;
  }
  100% {
    height: 9.4rem;
    opacity: 1;
  }
}
@keyframes dropdownsharedfileSlideDown {
  0% {
    height: 0;
    opacity: 0;
  }
  100% {
    height: 9.35rem;
    opacity: 1;
  }
}
@keyframes dropdownsharednofileSlideDown {
  0% {
    height: 0;
    opacity: 0;
  }
  100% {
    height: 4rem;
    opacity: 1;
  }
}
@keyframes dropdownnewFileFolder {
  0% {
    height: 0;
    opacity: 0;
    transform: translate3d(-10px, 0px, 0px); /* Start hidden above */
  }
  100% {
    height: 12.5rem;
    opacity: 1;
    transform: translate3d(10px, 5px, 0px);
  }
}
@keyframes dropdownnewGrpChat {
  0% {
    opacity: 0;
    transform: translate3d(10px, -10px, 0px);
  }
  100% {
    opacity: 1;
    transform: translate3d(20px, -10px, 0px);
  }
}

.dropdown-menu {
  opacity: 0;
  height: 0;
  overflow: hidden;
  pointer-events: none;
}

.grpChat {
  position: fixed;
  z-index: 9999;
  background-color: #000000;
  color: white;
  user-select: none;
}
.grpChat .dropdown-item:hover {
  background-color: #ffffff69 !important;
}
.replying-to-message {
  padding: 1rem;
  background-color: #000000;
  margin-bottom: 1rem;
  position: absolute;
  bottom: 100%;
  width: 100%;
}
.dropdown-menu.show {
  pointer-events: auto;
  animation: dropdownSlideDown 0.2s forwards ease-in-out;
}
.dropdown-menu.grpChat.show {
  pointer-events: auto;
  height: fit-content;
  animation: dropdownnewGrpChat 0.2s forwards ease-in-out;
}
.dropdown-menu.mof.show {
  position: fixed;
  transform: translate3d(-10rem, 1.5rem, 0px);
  pointer-events: auto;
  animation: dropdownpdfmobSlideDown 0.2s forwards ease-in-out;
}
.dropdown-menu.senter.show {
  pointer-events: auto;
  position: fixed;
  animation: dropdownfileSlideDown 0.2s forwards ease-in-out;
}

.dropdown-menu.filer.show {
  pointer-events: auto;
  animation: dropdownfileSlideDown 0.2s forwards ease-in-out;
}
.dropdown-menu.sharerFolder.show {
  pointer-events: auto;
  animation: dropdownShFolSlideDown 0.2s forwards ease-in-out;
}
.dropdown-menu.sharedfiler.show {
  pointer-events: auto;
  animation: dropdownsharedfileSlideDown 0.2s forwards ease-in-out;
}
.dropdown-menu.sharednofiler.show {
  pointer-events: auto;
  animation: dropdownsharednofileSlideDown 0.2s forwards ease-in-out;
}

#uploader1.show {
  width: 14rem !important;
  animation: dropdownnewFileFolder 0.2s forwards ease-in-out !important;
}

.dropdown-menu button {
  background: none;
  border: none;
  padding: 10px;
  text-align: left;
  cursor: pointer;
}

.dropdown-menu button:hover {
  background-color: #f8f9fa; /* Light gray */
}
/* Remove the default dropdown arrow */
#dropdownMenuButton2.dropdown-toggle::after {
  display: none;
}
.dropdownMenuButton2 {
  padding: 0.5rem;
  background-color: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none !important;
  box-shadow: none !important;
}
.dropdownMenuButton2::after {
  border: none !important;
  box-shadow: none !important;
}
#dropdownMenuButton1 {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 10rem;
  height: 5rem;
  border-radius: 1.5rem;
  font-size: x-large;
  background-color: #ccc0fe;
  color: black;
  z-index: 5; /* Ensure it's above other content */
}

#dropdownMenuButton1:hover {
  background-color: rgb(83, 83, 83);
  color: white;
}
#dropdownMenuButton1.dropdown-toggle::after {
  display: none;
}
.iconic {
  display: flex;
  justify-content: space-between;
}

.fa-solid fa-ellipsis-vertical {
  font-size: x-large;
}

.wrapper {
  min-height: 100vh;
  display: flex;
  justify-content: space-between;
  background-color: #000000 !important;
  opacity: 1;
  background-image: url("./icons/backgroundAppDesk.jpg");
  background-size: cover;
}

#wrapper {
  margin: 5rem 0 0 0;
  width: 85%;
  overflow: hidden; /* Hide overflow on the wrapper */
}

.fileFolderaccess {
  position: fixed; /* Keep the component fixed */
  top: 5rem; /* Adjust to match the margin of #wrapper */
  left: 15%; /* Align with the rest of the content */
  right: 0;
  bottom: 0;
  padding: 3rem 2rem 0rem 2rem;
  border-left: 0.5px solid #ccc0fe;
  border-top: 0.5px solid #ccc0fe;
  border-radius: 2rem 0rem 0rem 0rem;
  background-color: transparent;
  overflow-y: auto; /* Enable vertical scrolling */
  height: calc(100vh - 5rem);
}
.fileFolderaccess2 {
  position: fixed; /* Keep the component fixed */
  top: 5rem; /* Adjust to match the margin of #wrapper */
  left: 15%; /* Align with the rest of the content */
  right: 0;
  bottom: 0;
  padding: 3rem 2rem 0rem 2rem;
  border-left: 0.5px solid #ccc0fe;
  border-top: 0.5px solid #ccc0fe;
  border-radius: 2rem 0rem 0rem 0rem;
  background-color: transparent;
  overflow-y: auto; /* Enable vertical scrolling */
  height: calc(100vh - 5rem);
}

.fileFolderaccess .content {
  overflow-y: auto; /* Scroll within the fileFolderaccess */
  padding: 2rem 0rem 0rem 0rem;
}

a {
  text-decoration: none !important;
  color: #ffffff !important;
}

.breadcrumb {
  border-bottom: 3px solid grey;
  padding: 0.5rem;
}
.breadcrumb-item + .breadcrumb-item::before {
  color: #ccc0fe;
}

.search-results {
  position: absolute;
  top: 100%; /* Adjust based on your layout */
  left: 0;
  right: 0;
  background: white;
  border: 1px solid #ccc;
  border-radius: 4px;
  z-index: 1000;
}

.search-results {
  list-style: none;
  padding: 0;
  margin: 0;
}

.search-results li {
  padding: 8px;
  border-bottom: 1px solid #ddd;
}

.search-results li:last-child {
  border-bottom: none;
}

.search-results li:hover {
  background-color: #f0f0f0;
}

.loading {
  position: absolute;
  top: 100%; /* Adjust based on your layout */
  left: 0;
  right: 0;
  background: white;
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 8px;
  text-align: center;
  z-index: 1000;
}

#menu .nav-item {
  padding: 1rem;
  font-size: large;
  cursor: pointer;
  width: 100%;
}
#menu .nav-item:hover {
  background-color: #4d62ff23;
}
#menu .nav-item2 {
  padding: 1rem;
  font-size: large;
  cursor: pointer;
  width: 100%;
}

#menu .nav-link {
  color: white;
}

/* Initial state of the dropdown menu */
ul#uploader1 {
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 100%; /* Position just below the button */
  left: 0;
  margin: 0;
  opacity: 0;
  transition: transform 0.3s ease-in-out, opacity 0.3s ease-in-out; /* Smooth transition */
  width: 18rem;
  visibility: hidden; /* Ensure it's not visible initially */
}

/* State when the dropdown is visible */
ul#uploader1.show {
  opacity: 1;
  visibility: visible; /* Make it visible */
}

.navbar-nav .nav-link {
  color: white;
}
.navbar-nav .nav-link.active {
  color: white;
}
.navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28255, 255, 255, 0.55%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}
.search-results {
  color: black;
}

#jk {
  font-size: large;
  transition: all 0.2s ease-in-out;
}
#jk:hover {
  font-size: xx-large;
}
/* Search Bar new 


/* SearchBar.css */

.searcher {
  margin-left: auto;
  display: flex;
  align-items: center;
  margin-bottom: 2rem;
}

/* App.css */
.searchbar2 {
  margin-bottom: auto;
  margin-top: auto;
  height: 60px;
  background-color: #ccc0fe;
  border-radius: 25px; /* Default border radius */
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  overflow: hidden;
  width: 90vw;
}

.searchbar2-expanded {
  border-radius: 30px 30px 0 0; /* Border radius when search results are shown */
}

.search_input2 {
  color: rgb(0, 0, 0);
  border: 0;
  outline: 0;
  background: none;
  width: 50px; /* Initial small width */
  caret-color: transparent;
  line-height: 40px;
  transition: width 0.4s linear;
}

.search_input2.expanded,
.searchbar2:hover > .search_input2 {
  width: 450px; /* Expands to the left */
  caret-color: red;
  padding: 0 10px;
}
.search_input1 {
  color: rgb(0, 0, 0);
  border: 0;
  outline: 0;
  background: none;
  width: 100%; /* Initial small width */
  caret-color: transparent;
  line-height: 40px;
  transition: width 0.4s linear;
  caret-color: red;
  padding: 0 10px;
}

.search_icon {
  height: 40px;
  width: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  color: #040014;
  text-decoration: none;
}

.search-results2 {
  position: absolute;
  color: black;
  right: 12; /* Align search results dropdown to the right */
  background: #e5e5e5;
  border-radius: 0px 0px 4px 4px;
  /* box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1); */
  width: 31.9rem;
  max-height: 300px;
  overflow-y: auto;
  z-index: 1000;
  list-style: none;
  padding: 0;
  margin-top: 0px;
}
.search-results2 li {
  padding: 0.5rem;
}
.search-results2 li:hover {
  background-color: #e4e4e4;
}
.search-results1 {
  color: black;
  background: #e5e5e5;
  position: absolute;
  border-radius: 0px 0px 25px 25px;
  width: 90vw;
  max-height: 300px;
  overflow-y: auto;
  z-index: 50;
  overflow-wrap: anywhere;
  list-style: none;
  padding: 0;
  top: 5.77rem;
}
.search-results1 li {
  padding: 0.5rem;
}
.search-results1 li:hover {
  background-color: #c9c9c9;
  cursor: pointer;
}

/* App.css or PdfViewer.css */
.pdf-viewer-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(44, 44, 44, 0.836);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 100;
  padding-top: 5rem;
  overflow: auto;
}
.rpv-core__spinner .rpv-core__spinner--animating {
  display: none;
}

.pdf-viewer-content {
  position: relative;
  background: transparent !important;
  max-height: 90vh;
  width: 60%; /* Keeps the content resizing automatically */
  margin: auto;
  z-index: 200; /* Ensure the PDF content is above the overlay */
}
.pdf-viewer-inner {
  display: flex;
  justify-content: center;
}

.rpv-core__text-layer {
  pointer-events: none;
  z-index: 1005;
}
.rpv-core__inner-page,
.rpv-core__doc-loading,
.rpv-core__viewer,
.rpv-core__viewer--light {
  background: transparent !important;
  z-index: 1005;
}
.rpv-core__doc-error {
  overflow: none !important;
  background: transparent !important;
}
.rpv-core__inner-pages {
  overflow: none;
  z-index: 1005;
}

.pdf-viewer-options {
  display: flex;
  z-index: 1001;
  justify-content: space-between;
  padding-right: 1rem;
}

.pdf-viewer-options button {
  background: transparent;
  border: none;
  color: white;
  font-size: 1.5rem;
  padding: 0rem 2rem;
}

.pdf-viewer-header {
  position: fixed;
  top: 0px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  z-index: 1001; /* Ensure it is above other content */
  width: -webkit-fill-available;
  height: 4.5rem;
  background: rgba(66, 66, 66, 0.868);
}
.initialer {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  min-width: 20%;
  padding: 0rem 2rem 0rem 1rem;
}

.pdf-viewer-header .close-button {
  background: transparent;
  border: none;
  font-size: 1.5rem;
  color: white;
  cursor: pointer;
}

.pdf-viewer-header .file-name {
  color: white;
  font-size: x-large;
}

.spinner-container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: transparent;
  z-index: 1001;
}

.pdf-viewer-footer {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  background: transparent; /* Dark grey background */
  color: white;
  text-align: center;
  padding: 10px;
  z-index: 1002; /* Ensures it's above other elements */
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px; /* Space between buttons and page number */
}

.zoom-button {
  background: #555; /* Slightly lighter grey for buttons */
  border: none;
  color: white;
  padding: 10px;
  margin: 0;
  cursor: pointer;
  border-radius: 5px;
  font-size: 1.2rem;
}

.zoom-button:hover {
  background: #777; /* Slightly lighter grey for hover effect */
}

.nav-button {
  background: #555; /* Slightly lighter grey for navigation buttons */
  border: none;
  color: white;
  padding: 10px;
  margin: 0 5px;
  cursor: pointer;
  border-radius: 5px;
  font-size: 1.2rem;
}

.nav-button:hover {
  background: #777; /* Slightly lighter grey for hover effect */
}

.page-info {
  font-size: 1rem;
  width: 10rem;
}
.PageToolbar {
  background-color: #000000ad;
  border-radius: 10rem;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  width: 20rem;
  height: 3rem;
}
.rpv-core__minimal-button {
  color: white !important;
}
.rpv-core_icon {
  width: 1rem !important;
}

/* Targeting the spinner elements */
.rpv-core__doc-loading,
.rpv-core__spinner,
.rpv-core__spinner--animating {
  display: flex; /* Ensure spinner is visible */
  align-items: center;
  justify-content: center;
}

.rpv-core__spinner {
  width: 3rem; /* Adjust size as needed */
  height: 3rem;
  border: 0.4em solid rgba(0, 0, 0, 0.1); /* Light gray background */
  border-radius: 50%;
  border-top-color: #007bff; /* Change this to your desired color */
  animation: spinner-border 0.75s linear infinite;
}

/* Animation keyframes for spinner */
@keyframes spinner-border {
  to {
    transform: rotate(360deg);
  }
}
.fileitem {
  overflow-wrap: anywhere;
}
/*Main Spinner*/
.spinner-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.785); /* Transparent black background */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 9999; /* Ensures it is on top of other content */
}
.userList ul {
  height: -webkit-fill-available !important;
}
.loadertitle {
  color: rgb(107, 208, 255);
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-weight: 500;
  font-family: "Courier New", Courier, monospace; /* Monospace font for better typing effect */
  overflow: hidden; /* Ensures the text is hidden while typing */
  font-weight: 600;
  border-right: 0.15em solid white;
  height: 2rem;
  white-space: nowrap; /* Prevents the text from wrapping */
  width: 0; /* Initially hide text */
  animation: typing 2s steps(15, end) infinite,
    blink-caret 0.35s step-end infinite;
  margin-top: 1.7rem;
}

/* Typing effect */
@keyframes typing {
  0% {
    width: 0rem;
  }
  25% {
    width: 8.5rem;
  }
  50% {
    width: 8.5rem;
  }
  75% {
    width: 8.5rem;
  }
  100% {
    width: 0rem;
  }
}
.PaymentButton--rzp-dark-standard.svelte-ekc7fv.svelte-ekc7fv {
  background: transparent !important;
}
#razorpay-checkout-v2-container.loaded {
  max-width: 100% !important;
}

/* Blinking caret */
@keyframes blink-caret {
  from,
  to {
    border-color: transparent;
  }
  50% {
    border-color: white;
  }
}

.smiley {
  width: 10em;
  height: 10em;
}
.smiley__eye1,
.smiley__eye2,
.smiley__mouth1,
.smiley__mouth2 {
  animation: eye1 1.5s ease-in-out infinite;
  stroke: rgb(239, 239, 239);
}
.smiley__eye1,
.smiley__eye2 {
  transform-origin: 64px 64px;
}
.smiley__eye2 {
  animation-name: eye2;
}
.smiley__mouth1 {
  animation-name: mouth1;
}
.smiley__mouth2 {
  animation-name: mouth2;
  visibility: hidden;
}

/* Dark theme */
@media (prefers-color-scheme: dark) {
  :root {
    --bg: hsl(var(--hue), 90%, 10%);
    --fg: hsl(var(--hue), 90%, 90%);
  }
}

/* Animations */
@keyframes eye1 {
  from {
    transform: rotate(-260deg) translate(0, -56px);
  }
  50%,
  60% {
    animation-timing-function: cubic-bezier(0.17, 0, 0.58, 1);
    transform: rotate(-40deg) translate(0, -56px) scale(1);
  }
  to {
    transform: rotate(225deg) translate(0, -56px) scale(0.35);
  }
}
@keyframes eye2 {
  from {
    transform: rotate(-260deg) translate(0, -56px);
  }
  50% {
    transform: rotate(40deg) translate(0, -56px) rotate(-40deg) scale(1);
  }
  52.5% {
    transform: rotate(40deg) translate(0, -56px) rotate(-40deg) scale(1, 0);
  }
  55%,
  70% {
    animation-timing-function: cubic-bezier(0, 0, 0.28, 1);
    transform: rotate(40deg) translate(0, -56px) rotate(-40deg) scale(1);
  }
  to {
    transform: rotate(150deg) translate(0, -56px) scale(0.4);
  }
}
@keyframes eyeBlink {
  from,
  25%,
  75%,
  to {
    transform: scaleY(1);
  }
  50% {
    transform: scaleY(0);
  }
}
@keyframes mouth1 {
  from {
    animation-timing-function: ease-in;
    stroke-dasharray: 0 351.86;
    stroke-dashoffset: 0;
  }
  25% {
    animation-timing-function: ease-out;
    stroke-dasharray: 175.93 351.86;
    stroke-dashoffset: 0;
  }
  50% {
    animation-timing-function: steps(1, start);
    stroke-dasharray: 175.93 351.86;
    stroke-dashoffset: -175.93;
    visibility: visible;
  }
  75%,
  to {
    visibility: hidden;
  }
}
@keyframes mouth2 {
  from {
    animation-timing-function: steps(1, end);
    visibility: hidden;
  }
  50% {
    animation-timing-function: ease-in-out;
    visibility: visible;
    stroke-dashoffset: 0;
  }
  to {
    stroke-dashoffset: -351.86;
  }
}
/* Image Viewer Overlay */
.image-viewer-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(44, 44, 44, 0.836);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 100;
  overflow: auto;
  padding-top: 5rem;
}

/* Image Viewer Content */
.image-viewer-content {
  position: relative;
  max-height: 90vh;
  width: 100%;
  margin: auto;
  z-index: 200;
}

/* Image Viewer Header */
.image-viewer-header {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  z-index: 300;
  width: 100%;
  height: 4.5rem;
  background: rgba(66, 66, 66, 0.868);
}

/* Initial Elements in Header */
.initialer {
  display: flex;
  align-items: center;
  padding: 0 2rem 0 1rem;
}

/* Close Button in Header */
.image-viewer-header .close-button {
  background: transparent;
  border: none;
  font-size: 1.5rem;
  color: white;
  cursor: pointer;
}

/* File Name in Header */
.image-viewer-header .file-name {
  color: white;
  font-size: x-large;
  margin-left: 1rem;
}

/* Image Viewer Options (Rename, Download, etc.) */
.image-viewer-options {
  display: flex;
  justify-content: space-between;
  width: 25%;
  padding-right: 1rem;
  z-index: 301;
}

.image-viewer-options button {
  background: transparent;
  border: none;
  color: white;
  font-size: 1.5rem;
  padding: 0 2rem;
}

/* Navigation Buttons for Image Gallery */
.img-navigation-button-left,
.img-navigation-button-right {
  position: fixed;
  top: 50%;
  transform: translateY(-50%);
  z-index: 300;
}

.img-navigation-button-left {
  left: 0;
}

.img-navigation-button-right {
  right: 0;
}

.img-navigation-button-left button,
.img-navigation-button-right button {
  background: rgba(0, 0, 0, 0.5);
  border: none;
  color: white;
  font-size: 24px;
  padding: 10px;
  cursor: pointer;
  width: 3rem;
  height: 5rem;
  margin: 0.4rem;
  border-radius: 30%;
  transition: background 0.3s;
}

.img-navigation-button-left button:disabled,
.img-navigation-button-right button:disabled {
  background: rgba(0, 0, 0, 0.3);
  cursor: not-allowed;
}

.img-navigation-button-left button:hover,
.img-navigation-button-right button:hover {
  background: rgba(0, 0, 0, 0.7);
}

.modal-dialog {
  height: -webkit-fill-available;
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-backdrop {
  position: relative;
}

#renamemodal {
  height: auto !important;
  width: auto !important;
}
#renamemodal2 {
  height: auto !important;
  width: auto !important;
}
#createfoldermodal {
  height: auto !important;
  width: auto !important;
}
.sharedHead {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 2rem 1rem;
}
.sharedselector {
  width: 40%;
  display: flex;
  justify-content: center;
}
.shselectbutton {
  width: 5rem;
  height: 2.5rem;
  border: none;
  margin: 0rem 0.1rem;
}
.sharedVault {
  color: aqua !important;
}

.sharedVaultFileFolder {
  height: 100%;
  display: flex;
  flex-direction: column;
  width: 100%;
}
.vaultfile {
  margin-top: 3rem;
}
/* .loader {
  width: 50px;
  aspect-ratio: 1;
  display: grid;
  position: fixed !important;
  top: 8rem !important;
  right: 2rem !important;
  z-index: 9999 !important;
  animation: l14 4s infinite;
  left: auto !important;
  transform-origin: center !important;
  transform: translate(0%, 0%) !important;
}

.loader::before,
.loader::after {
  content: "";
  grid-area: 1/1;
  border: 8px solid;
  border-radius: 50%;
  border-color: red red #ffffff00 #ffffff00;
  mix-blend-mode: darken;
  animation: l14 1s infinite linear;
}

.loader::after {
  border-color: #0000 #0000 blue blue;
  animation-direction: reverse;
}

@keyframes l14 {
  100% {
    transform: rotate(1turn);
  }
} */

.loader {
  box-sizing: border-box;
  display: inline-block;
  width: 50px;
  height: 80px;
  border-top: 5px solid #b9a5ff;
  border-bottom: 5px solid #b9a5ff;
  left: auto !important;
  position: fixed !important; /* Use fixed positioning to ensure it stays at the top right */
  top: 7rem !important; /* 5rem from the top */
  right: 2rem !important; /* 2rem from the right */
  background: linear-gradient(#653fff 30px, transparent 0) no-repeat;
  background-size: 2px 40px;
  background-position: 50% 0px;
  animation: spinx 5s linear infinite;
  z-index: 1000 !important; /* Ensure it's on top of other elements */
}

.loader:before,
.loader:after {
  content: "";
  width: 40px;
  left: 50%;
  height: 35px;
  position: absolute;
  top: 0;
  transform: translateX(-50%);
  background: rgba(255, 255, 255, 0.4);
  border-radius: 0 0 20px 20px;
  background-size: 100% auto;
  background-repeat: no-repeat;
  background-position: 0 0px;
  animation: lqt 5s linear infinite;
}

.loader:after {
  top: auto;
  bottom: 0;
  border-radius: 20px 20px 0 0;
  animation: lqb 5s linear infinite;
}

@keyframes lqt {
  0%,
  100% {
    background-image: linear-gradient(#653fff 40px, transparent 0);
    background-position: 0% 0px;
  }
  50% {
    background-image: linear-gradient(#653fff 40px, transparent 0);
    background-position: 0% 40px;
  }
  50.1% {
    background-image: linear-gradient(#653fff 40px, transparent 0);
    background-position: 0% -40px;
  }
}

@keyframes lqb {
  0% {
    background-image: linear-gradient(#653fff 40px, transparent 0);
    background-position: 0 40px;
  }
  100% {
    background-image: linear-gradient(#653fff 40px, transparent 0);
    background-position: 0 -40px;
  }
}

@keyframes spinx {
  0%,
  49% {
    transform: rotate(0deg);
    background-position: 50% 36px;
  }
  51%,
  98% {
    transform: rotate(180deg);
    background-position: 50% 4px;
  }
  100% {
    transform: rotate(360deg);
    background-position: 50% 36px;
  }
}

/* Chat Component whole CSS*/

.ChatUserList {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 25%;
  overflow: hidden;
  position: sticky;
  top: 0;
  height: 85vh;
  overflow-y: auto;
  padding: 0rem;
  /* padding-right: 1rem; */
}
.ChatUserList ul li {
  font-size: larger;
  margin: 1rem 0rem;
}

.userList {
  border: 0.2rem solid #cebffc;
  width: 100%;
  height: 80%;
  border-radius: 1rem;
  padding: 0rem 0.5rem;
  overflow-y: auto;
}
/* Styling the scrollbar track */
.userList::-webkit-scrollbar {
  display: none !important;
}

.ChatArea {
  width: 70%;
  overflow: hidden;
  height: 82vh;
  border-radius: 1rem 1rem 1rem 1rem;
}

.chatAreahead {
  position: sticky;
  top: 0;
  z-index: 10;
  height: 10%;
  width: 100%;
  padding: 2rem 1rem;
  background-color: #67676763;
  color: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0;
}

.charAreafoot {
  position: sticky;
  bottom: 0;
  height: 10%;
  display: flex;
  justify-content: space-between;
  padding: 0;
  margin-top: 0.5rem;
}
.table-responsive::-webkit-scrollbar {
  height: 0.2rem !important;
}
.messagesContainer {
  height: 78%;
  overflow-y: scroll;
  background-color: #000a14;
  opacity: 1;
  background-image: radial-gradient(#45f7ee23 0.5px, transparent 0.5px),
    radial-gradient(#45f7ee3d 0.5px, #000a14b0 0.5px);
  background-size: 20px 20px;
  border-radius: 0rem 0rem 1rem 1rem;
  font-size: larger;
}
.messagesNonContainer {
  height: 78%;
  overflow-y: scroll;
  background-color: #ffffff;

  opacity: 1;
  background-image: radial-gradient(
      #5f45f7 1.2000000000000002px,
      transparent 1.2000000000000002px
    ),
    radial-gradient(#5f45f7 1.2000000000000002px, #ffffff 1.2000000000000002px);
  background-size: 48px 48px;
  background-position: 0 0, 24px 24px;

  border-radius: 0rem 0rem 1rem 1rem;
  font-size: larger;
}
/* Styling the scrollbar track */
.messagesContainer::-webkit-scrollbar {
  width: 12px; /* Set the width of the scrollbar */
}

/* Styling the scrollbar thumb */
.messagesContainer::-webkit-scrollbar-thumb {
  min-height: 8rem;
  background-color: #7f94ff90; /* Color of the scrollbar */
  border-radius: 5rem; /* Rounded corners of the scrollbar */
}

/* Hover state for the scrollbar thumb */
.messagesContainer::-webkit-scrollbar-thumb:hover {
  background-color: #9389ff; /* Darker color on hover */
}

.toolbut {
  background-color: transparent;
  color: white;
  border: none;
  border-radius: 1rem;
  padding: 0.5rem;
  cursor: pointer;
  margin: 0.5rem;
}
.toolbut:hover {
  background-color: rgba(255, 255, 255, 0.078);
}
.msgNone {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.msgNone .nomsg {
  background-color: #007bff;
}

.message {
  display: flex;
  margin-bottom: 10px;
  align-items: center;
}

.message.you {
  justify-content: flex-end; /* Aligns "You" messages to the right */
}

.message.other {
  justify-content: flex-start; /* Aligns selectedUser messages to the left */
}

.fa-duotone .fa-solid .fa-trash .fa-bounce {
  --fa-primary-color: red;
  --fa-secondary-colot: blue;
}

.messageContent {
  padding: 10px;
  border-radius: 10px;
  max-width: 70%;
  word-wrap: break-word;
}

.messageContent.you {
  color: rgb(0, 0, 0);
  align-self: flex-end;
}

.messageContent.other {
  color: black;
  align-self: flex-start;
}

.Nochathello {
  width: 70%;
  overflow: hidden;
  height: 80vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}
.emojiPickerContainer {
  position: absolute;
  bottom: 70px; /* Adjust based on your layout */
  left: 10px;
}
.gifPickerContainer {
  position: absolute;
  bottom: 70px; /* Adjust based on your layout */
  left: 10px;
}
.gifMessage {
  border-radius: 0.5rem;
  margin-top: 5px;
  margin-bottom: 5px;
  cursor: pointer;
}

.gifGrpmessage {
  min-height: 15rem;
  border-radius: 0.5rem;
  max-height: 20rem;
  margin-top: 5px;
  margin-bottom: 5px;
  cursor: pointer;
}

.giferX246 {
  cursor: pointer;
}
.emptyVault {
  margin-top: 2rem;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

/*Teams*/

.aqua-button {
  font-size: 1.1rem;
  color: hsla(190, 70%, 10%, 0.9);
  font-weight: 500;
  letter-spacing: -0.025em;
  background-color: hsl(190, 50%, 90%);
  background-image: linear-gradient(
    180deg,
    hsl(194, 100%, 79%) 45%,
    hsla(250, 60%, 95%, 0.5) 100%
  );
  background-repeat: no-repeat;
  background-position: center 45%;
  background-size: 100% 200%;
  padding: 0.5rem 1rem;
  border-radius: 5rem;
  border: none;
  box-shadow: 0 -0.5em 0.5em transparent, 0 0.5em 0.5em transparent,
    0 0.25em 0.3em -0.2em hsla(190, 50%, 50%, 0.46),
    0 0.25em 0.75em hsla(190, 40%, 40%, 0.3);
  position: relative;
  transition: all 0.5s ease;
  outline: none;
}

.aqua-button::before,
.aqua-button::after {
  content: "";
  inset: 0;
  position: absolute;
  border-radius: 5rem;
}

.aqua-button::before {
  background-image: radial-gradient(
      ellipse,
      hsla(190, 100%, 90%, 0.8) 20%,
      transparent 50%,
      transparent 200%
    ),
    linear-gradient(
      90deg,
      #404040 -10%,
      transparent 30%,
      transparent 70%,
      #404040 110%
    );
  box-shadow: inset 0 0.25em 0.75em rgba(0, 0, 0, 0.8),
    inset 0 -0.05em 0.2em rgba(255, 255, 255, 0.4),
    inset 0 -1px 3px hsla(190, 80%, 50%, 0.75);
  background-blend-mode: overlay;
  background-repeat: no-repeat;
  background-size: 200% 80%, cover;
  background-position: center 220%;
  mix-blend-mode: overlay;
  filter: blur(2px);
}

.aqua-button::after {
  background: linear-gradient(
    180deg,
    hsla(250, 100%, 90%, 0.9),
    hsla(250, 80%, 50%, 0.75) 40%,
    transparent 80%
  );
  top: 0.075em;
  left: 0.75em;
  right: 0.75em;
  bottom: 1.4em;
  filter: blur(0px);
  mix-blend-mode: screen;
}

.aqua-button:hover,
.aqua-button:active,
.aqua-button:focus {
  outline: none;
  box-shadow: 0 -0.2em 1em hsla(250, 70%, 80%, 0.3),
    0 0.5em 1.5em hsla(190, 70%, 80%, 0.5),
    0 0.25em 0.3em -0.2em hsl(190, 90%, 70%),
    0 0.25em 0.5em hsla(190, 20%, 30%, 0.2),
    inset 0 -2px 2px rgba(255, 255, 255, 0.2);
  background-position: center 44%;
}

.manageTeambut .btn,
.joiner .btn {
  border: 1px solid #ccc0fe;
  color: white;
}
.manageTeambut .btn:hover,
.joiner .btn:hover {
  border: 1px solid #ccc0fe;
  background-color: #ffffff;
  color: #000000;
}

/* From Uiverse.io by zanina-yassine */

/* The switch - the box around the slider */
.X23container {
  width: 5rem; /* Adjust as needed */
  height: 2rem;
  position: relative;
  padding: 0rem 0.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.checkboxUltra {
  opacity: 0;
  width: 0;
  height: 0;
  position: absolute;
}

.switch {
  width: 5rem;
  height: 100%;
  display: block;
  background-color: #e9e9eb;
  border-radius: 16px;
  cursor: pointer;
  padding: 0rem 0.2rem;
  position: relative;
  overflow: hidden; /* Ensure slider stays within bounds of switch */
}

.slider {
  width: 27px;
  height: 27px; /* Adjusted height to match width */
  position: absolute;
  top: 50%; /* Center vertically */
  transform: translateY(-50%); /* Adjust vertical centering */
  border-radius: 50%;
  background: #7e8cf9;
  box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.15), 0px 3px 1px rgba(0, 0, 0, 0.06);
  transition: transform 0.2s ease-out;
}

.checkboxUltra:checked + .switch {
  background-color: #4d61ff;
}

.checkboxUltra:checked + .switch .slider {
  transform: translateX(calc(4.6rem - 27px)) translateY(-50%);
  background-color: white;
}

.individual-teamX256 {
  padding: 0;
  background-color: transparent;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  /* height: 84vh; */
  overflow: hidden;
  color: white;
}

.indiTeamHeaderX256 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0rem 0rem 1.5rem 0rem;
}

.chat-areaX256 {
  position: relative;
  overflow: hidden;
  width: 100%;
  color: white;
  height: 75vh;
}

.grpmsgbody {
  display: flex;
  justify-content: space-between;
  align-items: center;
  overflow-wrap: anywhere;
}

.chat-areaX256 {
  background-color: rgba(187, 154, 233, 0.066);
  border-radius: 0.3rem;
}

.questions-areaX256 {
  background-color: rgba(67, 127, 183, 0.185);
  color: white;
  height: 75vh;
  width: 100%;
  overflow-y: auto;
  border-radius: 1rem;
}

.questions-areaX256 p {
  word-wrap: break-word; /* Forces long words to break */
  overflow-wrap: break-word; /* Same as word-wrap but more modern */
}

.messagesX256 {
  height: 80%;
  overflow-y: auto;
  color: rgb(0, 0, 0);
  background-color: #313338;
  opacity: 1;
  padding-bottom: 3rem !important;
}
.messagesX256 .messageX256 .grpmsgbody:hover {
  background-color: #ffffff0f;
}
.messagesX256 .messageX256 .giferX246:hover {
  background-color: #ffffff0f;
}

.messageX256 {
  padding: 0.2rem 0.5rem;
  border-radius: 0.3rem;
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: space-between;
}

.message-inputX256 {
  display: flex;
  align-items: center;
  position: absolute;
  bottom: 0;
  width: 100%;
  border-radius: 0;
}

.message-inputX256 input {
  flex: 1;
  padding: 10px;
  border-radius: 0;
  border: none;
  margin-right: 10px;
}

.message-inputX256 button {
  padding: 10px 15px;
  background-color: #7289da;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.delete-btnX256 {
  background-color: #f04747;
  color: white;
  border: none;
  padding: 5px 10px;
  border-radius: 5px;
  cursor: pointer;
  margin-left: 10px;
}

.questions-listX256 {
  display: flex;
  flex-direction: column;
}

.question-itemX256 {
  padding: 10px;
  border-bottom: 1px solid aqua;
  cursor: pointer;
  padding: 1rem 1rem;
  transition: all 0.1s ease-in-out;
}
.question-itemX256:hover {
  background-color: rgba(0, 157, 255, 0.491);
  color: rgb(255, 255, 255);
}

.question-detailsX256 {
  margin-top: 10px;
  padding: 1rem 1rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.question-textX256 {
  background-color: rgba(0, 0, 0, 0.68);
  padding: 1rem 1rem;
  border-radius: 0.5rem;
  width: 90%;
}
.reply-textX256 {
  background-color: rgba(0, 0, 0, 0.68);
  padding: 1rem 1rem;
  border-radius: 0.5rem;
  width: 90%;
}

.form-text {
  color: white;
}

.question-textX256 img {
  max-width: 100%;
  height: auto;
}

.repliesX256 {
  margin-top: 2.5rem;
  width: 100%;
}

.reply-itemX256 {
  padding: 5px;
  border-bottom: 1px solid #eee;
}

.reply-itemX256 img {
  max-width: 100%;
  height: auto;
}

.modalX256 {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modalX256 form {
  background: white;
  padding: 20px;
  border-radius: 5px;
  width: 90%;
  max-width: 500px;
}

.modalX256 form input,
.modalX256 form textarea {
  display: block;
  width: 100%;
  margin: 10px 0;
}

.modalX256 form button {
  margin: 10px 5px;
}

.image-gallery-slide .image-gallery-description {
  display: none;
}

.custom-tooltip {
  --bs-tooltip-bg: rgb(66, 66, 66); /* Background color */
  --bs-tooltip-z-index: 2; /* Background color */
  --bs-tooltip-color: #ffffff; /* Text color */
  --bs-tooltip-border-radius: 0.25rem; /* Border radius */
  --bs-tooltip-font-size: 0.875rem; /* Font size */
  --bs-tooltip-padding: 0.5rem 0.75rem; /* Padding */
}

.custom-tooltip .tooltip-inner {
  max-width: 30rem; /* Set the maximum width of the tooltip */
  width: 30rem; /* Ensure the width is applied */
}

.custom-tooltip .tooltip-arrow {
  color: #ffffff; /* Arrow color, same as background color */
}

.tooltip-custom .tooltip-highlight {
  color: red; /* Custom color for highlighted text */
}

pre {
  white-space: pre-wrap;
  word-wrap: break-word;
  width: 100%;
  overflow: auto;
  margin: 1rem 0rem !important;
}

.collapse-content {
  max-height: 0;
  overflow: hidden;
  transition: all 0.5s ease-in-out;
  padding: 0; /* Start with no padding when collapsed */
}

.collapse-content.open {
  margin-top: 1rem;
  padding: 0rem; /* Add padding when expanded */
}
.zroda.open {
  padding: 0rem 0rem 2rem 0rem;
  max-height: 20rem;
  overflow-y: auto;
}
.zroda::-webkit-scrollbar {
  display: none;
}

.loadingMorefiles {
  width: 8px;
  height: 30px;
  border-radius: 4px;
  display: block;
  position: relative;
  background: currentColor;
  color: #fff;
  box-sizing: border-box;
  animation: animloader 0.3s 0.3s linear infinite alternate;
}

.loadingMorefiles::after,
.loadingMorefiles::before {
  content: "";
  width: 8px;
  height: 30px;
  border-radius: 4px;
  background: currentColor;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 20px;
  box-sizing: border-box;
  animation: animloader 0.3s 0.45s linear infinite alternate;
}
.loadingMorefiles::before {
  left: -20px;
  animation-delay: 0s;
}

@keyframes animloader {
  0% {
    height: 35px;
  }
  100% {
    height: 4px;
  }
}

/*Mobile Navigation*/

/* Navbar styling */
.navbar {
  background: transparent;
  border-radius: 4px;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  box-shadow: 0px 1px 6px rgba(0, 0, 0, 0.2);
  width: 100%;
  height: 5rem;
  padding: 12px 0;
  align-items: center;
  justify-content: space-between;
}

.navbar .logo {
  padding: 0 20px;
}

.navbar .logo a {
  text-decoration: none;
  color: #333;
  font-size: 1.5em;
}

/* Burger button */
.burger {
  position: relative;
  border: none;
  background: none;
  padding: 10px;
  cursor: pointer;
  outline: none;
  transition: all 0.3s ease-in-out;
}

.burger span {
  display: block;
  width: 24px;
  height: 2px;
  background: white;
  margin: 5px 0;
  border-radius: 2px;
  transition: all 0.4s ease-in-out;
}

/* Animate burger when open */
.burger.open span:nth-child(1) {
  transform: translateY(7px) rotate(45deg);
}

.burger.open span:nth-child(2) {
  opacity: 0;
}

.burger.open span:nth-child(3) {
  transform: translateY(-7px) rotate(-45deg);
}

/* Menu */
.menu {
  z-index: 5;
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 5rem;
  left: 0;
  right: 0;
  max-height: 0;
  overflow: hidden;
  background: #e3dbff;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: max-height 0.3s ease-in-out;
}

.menu.open {
  max-height: 411px;
}

.menu > li {
  padding: 10px 20px;
  text-align: left;
}

.menu > li:hover {
  background: linear-gradient(141deg, #48ded4 0%, #a026bf 51%, #e82c75 75%);
}
.menu > li:hover a {
  color: #fff !important;
}

.menu > li a,
.menu > li button {
  text-decoration: none;
  color: #333 !important;
}

@import url("https://fonts.googleapis.com/css?family=Montserrat:400,400i,700");
.shareItemModf {
  font-family: "Montserrat";
}

.shareItemModf i {
  color: inherit;
}

.shareItemModf .banners-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 9999;
}

.shareItemModf .banner {
  color: white;
  font-weight: 700;
  padding: 1.5rem 1rem;
  display: flex;
  font-size: x-large;
  flex-direction: row;
  align-items: center;
}
.shareItemModf .banner .banner-message {
  flex: 1;
  padding: 0 2rem;
}
.shareItemModf .banner .banner-close {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.5rem;
  border-radius: 4px;
  cursor: pointer;
  transition: background 0.3s;
}
.shareItemModf .banner .banner-close:hover {
  background: rgba(0, 0, 0, 0.12);
}

.shareItemModf .banner.success {
  background: #10c15c;
}
.shareItemModf .banner.success::after {
  background: #10c15c;
}
.shareItemModf .banner.error {
  background: #ed1c24;
}
.shareItemModf .banner.error::after {
  background: #ed1c24;
}
.shareItemModf .banner.info {
  background: #3aedfe;
  color: black;
}
.shareItemModf .banner.info::after {
  background: #3aedfe;
  color: black;
}

.shareItemModf .banner::after {
  content: "";
  position: absolute;
  height: 10%;
  width: 100%;
  bottom: 100%;
  left: 0;
}

.shareItemModf .banner:not(.visible) {
  display: none;
  transform: translateY(-100%);
}

.shareItemModf .banner.visible {
  box-shadow: 0 2px 2px 2px rgba(0, 0, 0, 0.12);
  animation-name: banner-in;
  animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  animation-fill-mode: forwards;
  animation-iteration-count: 1;
  animation-direction: forwards;
}

@keyframes banner-in {
  0% {
    transform: translateY(-100%);
  }
  50% {
    transform: translateY(10%);
  }
  100% {
    transform: translateY(0);
  }
}
.shareItemModf .show-banner {
  appearance: none;
  background: #ededed;
  border: 0;
  padding: 1rem 2rem;
  border-radius: 4px;
  cursor: pointer;
  text-transform: uppercase;
  margin: 0.25rem;
}

@keyframes banner-out {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(10%);
  }
  100% {
    transform: translateY(-100%);
  }
}

.shareItemModf .banner.closing {
  box-shadow: 0 2px 2px 2px rgba(0, 0, 0, 0.12);
  animation-name: banner-out;
  animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  animation-fill-mode: forwards;
  animation-iteration-count: 1;
  animation-direction: normal;
}
